import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FormModal, ModalFooter } from 'pages/global/FormModal';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useAddCategoryGroupMutation, useUpdateCategoryGroupMutation } from 'features/reimbursements/reimbursements';

export function EditFolderModal({ payload, close }) {
    const { t } = useTranslation('global');

    const showModal = !!payload;

    return (
        <FormModal
            show={showModal}
            onHide={close}
            title={
                payload.hasOwnProperty('id') ? t('contentSidebar.map.title.edit') : t('contentSidebar.map.title.new')
            }
        >
            {showModal && <ModalContent group={payload} handleClose={close} />}
        </FormModal>
    );
}

function ModalContent({ group, handleClose }) {
    const history = useHistory();
    const { t } = useTranslation('global');
    const organisationId = useActiveOrganisation();
    const [addCategoryGroup] = useAddCategoryGroupMutation();
    const [updateCategoryGroup] = useUpdateCategoryGroupMutation();

    return (
        <Formik
            initialValues={{
                name: group.name ?? '',
                parent: group.parent ?? null,
                sortOrder: group.sortOrder ?? null,
                organisationId,
            }}
            validationSchema={NewFolderSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors, touched, dirty }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <Form.Group>
                            <Field
                                as={Form.Control}
                                id="name"
                                name="name"
                                placeholder={t('contentSidebar.map.titleField')}
                                isInvalid={errors.name && touched.name}
                                isValid={!errors.name && touched.name}
                            />
                        </Form.Group>
                    </Modal.Body>

                    <ModalFooter dirty={dirty} isSubmitting={isSubmitting} onHide={handleClose} />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        if (group.hasOwnProperty('id')) {
            updateCategoryGroup({ uri: group['@id'], body: values }).then(() => {
                setSubmitting(false);
                handleClose();
            });
        } else {
            addCategoryGroup(values).then(({ data }) => {
                setSubmitting(false);
                handleClose();

                // // Navigate to new group
                // history.push(
                //     generatePath(INDEX_PATH, {
                //         folder: Constants.folders.folder,
                //         folderId: data.id,
                //     }),
                // );
            });
        }
    }
}

const NewFolderSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});
