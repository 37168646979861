import { useTranslation } from 'react-i18next';

export default function getTemplateParameters(entityType, entity) {
    let type = entityType === 'block' ? entity?.blockType : entityType;

    switch (type) {
        case 'document':
            return documentTemplateParameters;
        case 'area':
            return articleTemplateParameters;
        case 'templateArea':
            return templateArticleTemplateParameters;
        case 'section':
            return chapterTemplateParameters;
        case 'text':
        case 'textExplanation':
        case 'list':
            return textBlockTemplateParameters;
        case 'table':
        case 'compensationTable':
        case 'reimbursement':
            return tableBlockTemplateParameters;
        case 'blockLayout':
            return blockLayoutBlockTemplateParameters;
        case 'file':
            return imageBlockTemplateParameters;
        case 'label':
            return ChoiceOptionsBlockTemplateParameters;
        case 'linked':
            return textBlockTemplateParameters;
        default:
            return {};
    }
}

// document
export const documentTemplateParameters = {
    Algemeen: {
        canLinkReimbursementStructure: true,
        canLinkLibrary: true,
        canUnlinkTemplate: true,
        canChangeIcon: true,
        canMoveChapters: true,
        notificationOnTemplateUpdates: true,
        maxNumberOfSections: 0,
    },
    Tags: {
        canViewDocTagsTab: true,
        canUserCreateTags: true,
        canUserSeeFindAndReplace: true,
    },
};

// chapter = section
export const chapterTemplateParameters = {
    Opties: {
        canDeleteChapters: true,
        canAddFreeAreas: true,
        canAddTemplateArticles: true,
        canMoveArticles: true,
    },
    Instellingen: {
        canSeeSettings: true,
        canChangeTitle: true,
        canChangeAutomaticItemNumbering: true,
    },
    Varianten: {
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeCustomPrefix: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

// article = area
export const articleTemplateParameters = {
    Opties: {
        LoadsAllBlocksFromTemplate: true,
        canMoveBlocks: true,
        canEditArticle: true,
        canDeleteArticle: true,
        canDuplicateArticle: true,
        canAddFreeBlocks: true,
    },
    Instellingen: {
        canSeeSettings: true,
        canModifyTitle: true,
        canModifyHeadingLevel: true,
        canModifyTitlePrefix: true,
        canModifyIcon: true,
        prefixOptions: '',
        canForceArticleModificationOnNewPage: true,
        canSeeTitleInDocumentModification: true,
        canChangeAutomaticItemNumbering: true,
        canChangeArticleTemplate: true,
        canChangeCustomPosition: true,
        canModifyMetadata: true,
    },
    Varianten: {
        preventVariantsForNewDocument: false,
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeCustomPrefix: true,
        canModifyCustomHeadingLevel: true,
        canChangeCustomPosition: true,
        canForceChangeNewPdfPage: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

// template area
export const templateArticleTemplateParameters = {
    Artikeltemplate: {
        canEditArticleTemplate: true,
        canRemoveArticleTemplate: true,
        canCreateFromArticleTemplate: true,
    },
};

// block = block, blockType = entity.type
export const textBlockTemplateParameters = {
    Instellingen: {
        canSeeSettings: true,
        canDeleteBlock: true,
        canDuplicateBlock: true,
        canChangeBlockLayout: true,
        canChangeContent: true,
        canChangeName: true,
        canChangeBlockNumberingValue: true,
        canChangeForceBlockOnNewPage: true,
    },
    Varianten: {
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeForceBlockOnNewPageVariants: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

export const tableBlockTemplateParameters = {
    Instellingen: {
        canSeeSettings: true,
        canDeleteBlock: true,
        canChangeContent: true,
        canChangePackageGroup: true,
        canChangeTableSettings: true,
        canChangeBlockNumberingValue: true,
        canChangeForceBlockOnNewPage: true,
    },
    Varianten: {
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeForceBlockOnNewPageVariants: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

export const blockLayoutBlockTemplateParameters = {
    Instellingen: {
        canSeeSettings: true,
        canDeleteBlock: true,
        canChangeContent: true,
        canChangeColumnsAndRows: true,
        canChangeTableSettings: true,
        canChangeForceBlockOnNewPage: true,
    },
    Varianten: {
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeForceBlockOnNewPageVariants: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

export const imageBlockTemplateParameters = {
    Instellingen: {
        canSeeSettings: true,
        canDeleteBlock: true,
        canChangeImageSettings: true,
        canChangeContent: true,
        canChangeBlockNumberingValue: true,
        canChangeForceBlockOnNewPage: true,
    },
    Varianten: {
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeForceBlockOnNewPageVariants: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

export const ChoiceOptionsBlockTemplateParameters = {
    Instellingen: {
        canSeeSettings: true,
        canChangeContent: true,
        canChangeName: true,
        canChangeBlockNumberingValue: true,
        canDeleteBlock: true,
    },
    Varianten: {
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeForceBlockOnNewPageVariants: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

export const PackageFeeBlockTemplateParameters = {
    Instellingen: {
        canSeeSettings: true,
        canChangePackage: true,
        canChangeBlockNumberingValue: true,
        canDeleteBlock: true,
    },
    Varianten: {
        canSeeVariantsSettings: true,
        canChangeVariants: true,
        canChangeForceBlockOnNewPageVariants: true,
    },
    Labels: {
        canSeeLabelsSettings: true,
        canChangeLabels: true,
    },
};

export function ParametersTranslation(key) {
    const { t } = useTranslation('documents');
    const translations = {
        //block
        canChangeColumnsAndRows: t(
            'document.navbar.template.tabs.settings.templateConstants.block.canChangeColumnsAndRows',
        ),
        canDeleteBlock: t('document.navbar.template.tabs.settings.templateConstants.block.canDeleteBlock'),
        canDuplicateBlock: t('document.navbar.template.tabs.settings.templateConstants.block.canDuplicateBlock'),
        canChangeContent: t('document.navbar.template.tabs.settings.templateConstants.block.canChangeContent'),
        canChangeName: t('document.navbar.template.tabs.settings.templateConstants.block.canChangeName'),
        canChangeBlockLayout: t('document.navbar.template.tabs.settings.templateConstants.block.canChangeBlockLayout'),
        canChangeBlockNumberingValue: t(
            'document.navbar.template.tabs.settings.templateConstants.block.canChangeBlockNumberingValue',
        ),
        canChangeForceBlockOnNewPage: t(
            'document.navbar.template.tabs.settings.templateConstants.block.canChangeForceBlockOnNewPage',
        ),
        canChangeForceBlockOnNewPageVariants: t(
            'document.navbar.template.tabs.settings.templateConstants.block.canChangeForceBlockOnNewPage',
        ),
        canChangeFile: t('document.navbar.template.tabs.settings.templateConstants.block.canChangeFile'),
        canChangeImageSettings: t(
            'document.navbar.template.tabs.settings.templateConstants.block.canChangeImageSettings',
        ),
        canChangeTableSettings: t(
            'document.navbar.template.tabs.settings.templateConstants.block.canChangeTableSettings',
        ),
        canChangePackage: t('document.navbar.template.tabs.settings.templateConstants.block.canChangePackage'),
        canChangePackageGroup: t(
            'document.navbar.template.tabs.settings.templateConstants.block.canChangePackageGroup',
        ),
        //chapter
        canAddFreeAreas: t('document.navbar.template.tabs.settings.templateConstants.chapter.canAddFreeAreas'),
        canDeleteChapters: t('document.navbar.template.tabs.settings.templateConstants.chapter.canDeleteChapters'),
        canAddTemplateArticles: t(
            'document.navbar.template.tabs.settings.templateConstants.chapter.canAddTemplateArticles',
        ),
        canMoveArticles: t('document.navbar.template.tabs.settings.templateConstants.chapter.canMoveArticles'),
        canSeeSettings: t('document.navbar.template.tabs.settings.templateConstants.chapter.canSeeSettings'),
        canChangeTitle: t('document.navbar.template.tabs.settings.templateConstants.chapter.canChangeTitle'),
        canChangeAutomaticItemNumbering: t(
            'document.navbar.template.tabs.settings.templateConstants.chapter.canChangeAutomaticItemNumbering',
        ),
        canSeeVariantsSettings: t(
            'document.navbar.template.tabs.settings.templateConstants.chapter.canSeeVariantsSettings',
        ),
        canChangeVariants: t('document.navbar.template.tabs.settings.templateConstants.chapter.canChangeVariants'),
        canChangeCustomPrefix: t(
            'document.navbar.template.tabs.settings.templateConstants.chapter.canChangeCustomPrefix',
        ),
        canSeeLabelsSettings: t(
            'document.navbar.template.tabs.settings.templateConstants.chapter.canSeeLabelsSettings',
        ),
        canChangeLabels: t('document.navbar.template.tabs.settings.templateConstants.chapter.canChangeLabels'),
        //article
        LoadsAllBlocksFromTemplate: t(
            'document.navbar.template.tabs.settings.templateConstants.article.LoadsAllBlocksFromTemplate',
        ),
        preventVariantsForNewDocument: t(
            'document.navbar.template.tabs.settings.templateConstants.article.preventVariantsForNewDocument',
        ),
        canAddFreeBlocks: t('document.navbar.template.tabs.settings.templateConstants.article.canAddFreeBlocks'),
        canMoveBlocks: t('document.navbar.template.tabs.settings.templateConstants.article.canMoveBlocks'),
        canEditArticle: t('document.navbar.template.tabs.settings.templateConstants.article.canEditArticle'),
        canDeleteArticle: t('document.navbar.template.tabs.settings.templateConstants.article.canDeleteArticle'),
        canDuplicateArticle: t('document.navbar.template.tabs.settings.templateConstants.article.canDuplicateArticle'),
        canModifyTitle: t('document.navbar.template.tabs.settings.templateConstants.article.canModifyTitle'),
        canModifyIcon: t('document.navbar.template.tabs.settings.templateConstants.article.canModifyIcon'),
        canModifyMetadata: t('document.navbar.template.tabs.settings.templateConstants.article.canModifyMetadata'),
        canModifyHeadingLevel: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canModifyHeadingLevel',
        ),
        canModifyTitlePrefix: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canModifyTitlePrefix',
        ),
        canForceArticleModificationOnNewPage: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canForceArticleModificationOnNewPage',
        ),
        canSeeTitleInDocumentModification: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canSeeTitleInDocumentModification',
        ),
        canChangeArticleTemplate: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canChangeArticleTemplate',
        ),
        canChangeCustomPosition: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canChangeCustomPosition',
        ),
        prefixOptions: t('document.navbar.template.tabs.settings.templateConstants.article.prefixOptions'),
        canModifyCustomHeadingLevel: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canModifyCustomHeadingLevel',
        ),
        canForceChangeNewPdfPage: t(
            'document.navbar.template.tabs.settings.templateConstants.article.canForceChangeNewPdfPage',
        ),
        //article template
        canEditArticleTemplate: t(
            'document.navbar.template.tabs.settings.templateConstants.templateArticle.canEditArticleTemplate',
        ),
        canRemoveArticleTemplate: t(
            'document.navbar.template.tabs.settings.templateConstants.templateArticle.canRemoveArticleTemplate',
        ),
        canCreateFromArticleTemplate: t(
            'document.navbar.template.tabs.settings.templateConstants.templateArticle.canCreateFromArticleTemplate',
        ),
        //document
        canMoveChapters: t('document.navbar.template.tabs.settings.templateConstants.document.canMoveChapters'),
        canLinkReimbursementStructure: t(
            'document.navbar.template.tabs.settings.templateConstants.document.canLinkReimbursementStructure',
        ),
        canLinkLibrary: t('document.navbar.template.tabs.settings.templateConstants.document.canLinkLibrary'),
        canUnlinkTemplate: t('document.navbar.template.tabs.settings.templateConstants.document.canUnlinkTemplate'),
        canChangeIcon: t('document.navbar.template.tabs.settings.templateConstants.document.canChangeIcon'),
        notificationOnTemplateUpdates: t(
            'document.navbar.template.tabs.settings.templateConstants.document.notificationOnTemplateUpdates',
        ),
        canViewDocTagsTab: t('document.navbar.template.tabs.settings.templateConstants.document.canViewDocTagsTab'),
        canUserCreateTags: t('document.navbar.template.tabs.settings.templateConstants.document.canUserCreateTags'),
        canUserSeeFindAndReplace: t(
            'document.navbar.template.tabs.settings.templateConstants.document.canUserSeeFindAndReplace',
        ),
        maxNumberOfSections: t('document.navbar.template.tabs.settings.templateConstants.document.maxNumberOfSections'),
    };

    return translations[key] || '';
}
