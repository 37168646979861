import { entityTypes } from '../translation/config/Constants';

export function idToUri(id: number, type: string): string {
    switch (type) {
        case 'Category':
            return `/api/reimbursement-api/api/categories/${id}`;
        case 'Reimbursement':
            return `/api/reimbursement-api/api/reimbursements/${id}`;
        case 'Product':
            return `/api/reimbursement-api/api/products/${id}`;
        case 'CollectiveGroup':
            return `/api/reimbursement-api/api/collective_groups/${id}`;
        case 'PropositionGroup':
            return `/api/reimbursement-api/api/proposition_groups/${id}`;
        case 'LabelFolder':
            return `/api/meta-api/api/label_folders/${id}`;
        case 'Block':
        case entityTypes.BLOCK:
            return `/api/block/${id}`;
        case 'Document':
        case entityTypes.DOCUMENT:
            return `/api/document/${id}`;
        case 'Area':
        case entityTypes.AREA:
            return `/api/areas/${id}`;
        case entityTypes.PUBLICATION:
            return `/api/publications/${id}`;
        case 'PublicationGroup':
            return `/api/publication_groups/${id}`;
        case 'CategoryGroup':
            return `/api/reimbursement-api/api/category_groups/${id}`;
    }

    return `` + id;
}
