import { useGetCategoryGroupsQuery } from 'features/reimbursements/reimbursements';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetCategoryGroups(parentId = null) {
    // const activeOrganisation = useActiveOrganisation();
    //
    const filterGroups = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, parentId) => parentId,
            (data, parentId) => {
                if (!data) {
                    return emptyArray;
                }

                return data.filter((categoryGroup) => categoryGroup.parent === parentId);
            },
        );
    }, []);

    const { categoryGroups } = useGetCategoryGroupsQuery(undefined, {
        selectFromResult: (result) => ({
            categoryGroups: filterGroups(result, parentId),
        }),
    });

    return categoryGroups;
}
