import {
    DocumentVariantSelect,
    FieldSelect,
    FormField,
    FormFieldWithTitle,
    InputCheck,
    InputField,
    InputSelect,
    Switch,
    Textarea,
} from '../../helpers/FieldHelper';
import Constants from '../../../../config/Constants';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import RestrictedContent from '../../../global/RestrictedContent';
import SectionTranslation from './SectionTranslation';
import { useFormikContext } from 'formik';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useGetDocumentVariants } from 'pages/documents_v2/hooks/useGetDocumentVariants';
import { PublicationProperty, templateProperties, useIsPropertySupported } from '../PublicationProperty';
import { useDocumentTemplate } from '../../hooks/useDocumentTemplate';
import { useTranslation } from 'react-i18next';

export default function Section({ index, contentItem }) {
    const document = useGetDocument(contentItem.documentId, false);
    const documentVariants = useGetDocumentVariants(contentItem.documentId);
    const canCapitalizeTags = useIsPropertySupported(templateProperties.section.capitalizeTags);
    const { t } = useTranslation('publications');

    const loading = document === undefined;

    // Try to get the selected template from the formik context
    const { values } = useFormikContext();
    const { html = false, pdf = false } = values;

    const documentTemplate = useDocumentTemplate();
    const { contentTypes = [], styles = [], supportedProperties = [] } = documentTemplate ?? {};

    // Show header when we have section related properties
    const hasSectionProperty = supportedProperties.some((_property) => _property.startsWith('section.'));

    const sections = document?.sections ?? [];
    const section = _.find(sections, ['id', contentItem.sectionId]);

    // Variants
    const baseVariant = documentVariants?.find((variant) => variant.parentId === null);

    // Content types
    const typeNames = {
        default: t('publication.settings.content.summary.expand.section.typeNames.standard'),
        reimbursement_diff: t('publication.settings.content.summary.expand.section.typeNames.inset'),
        appendix: t('publication.settings.content.summary.expand.section.typeNames.addendum'),
        package_summary: t('publication.settings.content.summary.expand.section.typeNames.reimbursementOverview'),
        clausuleblad: t('publication.settings.content.summary.expand.section.typeNames.clauseSheet'),
    };

    const availableTypes = contentTypes.map((type) => {
        return {
            label: typeNames[type] ?? t('publication.settings.content.summary.expand.section.unknownType'),
            value: type,
        };
    });

    // Styles
    const styleNames = {
        default: t('publication.settings.content.summary.expand.styleNames.standard'),
        noThemeHeadings: t('publication.settings.content.summary.expand.styleNames.noHeadlines'),
        oneColumnLayout: t('publication.settings.content.summary.expand.styleNames.oneColumnLayout'),
        glossary: t('publication.settings.content.summary.expand.styleNames.glossary'),
    };
    const availableStyles = styles.map((style) => {
        return {
            label: styleNames[style] ?? t('publication.settings.content.summary.expand.section.unknownStyle'),
            value: style,
        };
    });

    return (
        <>
            <DocumentVariantSelect
                name={`contentDecoded.${index}.variantId`}
                label="Variant*"
                baseVariant={baseVariant}
                availableVariants={section?.documentVariants ?? []}
                props={{
                    isDisabled: loading,
                    isLoading: loading,
                    placeholder: `${t('publication.settings.content.summary.expand.section.titleSelect')}...`,
                }}
            />

            {hasSectionProperty && (
                <span className="font-weight-bolder font-size-sm text-color">
                    {t('publication.settings.content.summary.expand.section.chapter')}
                </span>
            )}

            <PublicationProperty property={templateProperties.section.showTitleInDocument}>
                <Switch
                    label={t('publication.settings.content.summary.expand.section.displayTitle')}
                    name={`contentDecoded.${index}.properties.showTitleInDocument`}
                />
            </PublicationProperty>

            <PublicationProperty property={templateProperties.section.title}>
                <FormField
                    name={`contentDecoded.${index}.title`}
                    label={t('publication.settings.content.summary.expand.section.divergentTitle')}
                    props={{ disabled: !contentItem['properties']['showTitleInDocument'] }}
                />
            </PublicationProperty>

            <PublicationProperty property={templateProperties.section.showInChapters}>
                <Switch
                    label={t('publication.settings.content.summary.expand.section.displayOnOverview')}
                    name={`contentDecoded.${index}.properties.showInChapters`}
                />
            </PublicationProperty>

            <PublicationProperty property={templateProperties.section.showArticlesInChapters}>
                <>
                    {contentItem?.properties?.showInChapters === true && (
                        <Switch
                            label={t('publication.settings.content.summary.expand.section.displayArticlesOnOverview')}
                            name={`contentDecoded.${index}.properties.showArticlesInChapters`}
                            help={
                                contentItem?.properties?.showArticlesInChapters === true
                                    ? t(
                                          'publication.settings.content.summary.expand.section.displayArticlesOnOverviewHelp',
                                      )
                                    : ''
                            }
                        />
                    )}
                </>
            </PublicationProperty>

            <PublicationProperty property={templateProperties.section.tableOfContentsDescription}>
                <Textarea
                    label={t('publication.settings.content.summary.expand.section.additionalDescription')}
                    name={`contentDecoded.${index}.tableOfContentsDescription`}
                    props={{
                        rows: 2,
                        disabled:
                            contentItem?.properties?.showInChapters === true &&
                            contentItem?.properties?.showArticlesInChapters === true,
                    }}
                />
            </PublicationProperty>

            <PublicationProperty property={templateProperties.section.tocForceNewPage}>
                <>
                    <Switch
                        label={t('publication.settings.content.summary.expand.section.tocForceNewPage')}
                        name={`contentDecoded.${index}.properties.tocForceNewPage`}
                    />

                    {contentItem['properties']['tocForceNewPage'] === true && (
                        <FormFieldWithTitle
                            name={`contentDecoded.${index}.properties.tocForceNewPageTitle`}
                            label={`${t('publication.settings.content.summary.expand.section.chapterArticleName')}*`}
                        />
                    )}
                </>
            </PublicationProperty>

            <PublicationProperty property={templateProperties.section.numberingSettings}>
                <Switch
                    label={t('publication.settings.content.summary.expand.section.sequentialNumbering')}
                    name={`contentDecoded.${index}.properties.sequentialNumbering`}
                />
            </PublicationProperty>

            {contentItem.type === 'package_summary' && (
                <>
                    <div className="font-weight-bold mb-3 pt-2">
                        {t('publication.settings.content.summary.expand.section.reimbursements')}
                    </div>
                    <Textarea
                        label={`${t('publication.settings.content.summary.expand.section.reimbursementsOverview')}*`}
                        name={`contentDecoded.${index}.properties.packageSummaryFilter`}
                        props={{ rows: 2 }}
                        help={t('publication.settings.content.summary.expand.section.reimbursementsOverviewHelp')}
                    />
                    <Textarea
                        label={t('publication.settings.content.summary.expand.section.excludeArticles')}
                        name={`contentDecoded.${index}.properties.packageSummaryExcludes`}
                        props={{ rows: 2 }}
                        help={t('publication.settings.content.summary.expand.section.excludeArticlesHelp')}
                    />
                </>
            )}

            {document && (
                <RestrictedContent module={Constants.modules.translations}>
                    <PublicationProperty property={templateProperties.section.translate}>
                        <SectionTranslation contentItem={contentItem} index={index} document={document} />
                    </PublicationProperty>
                </RestrictedContent>
            )}

            {(availableTypes.length > 1 || availableStyles.length > 1 || canCapitalizeTags) && (
                <span className="font-weight-bolder font-size-sm text-color">
                    {t('publication.settings.content.summary.expand.section.formatting')}
                </span>
            )}

            {availableTypes.length > 1 && (
                <FieldSelect
                    name={`contentDecoded.${index}.type`}
                    label={`${t('publication.settings.content.summary.expand.section.type')}*`}
                    options={availableTypes}
                />
            )}

            {availableStyles.length > 1 && (
                <FieldSelect
                    name={`contentDecoded.${index}.style`}
                    label={`${t('publication.settings.content.summary.expand.section.style')}*`}
                    options={availableStyles}
                />
            )}

            <PublicationProperty property={templateProperties.section.capitalizeTags}>
                <>
                    <Switch
                        label={t('publication.settings.content.summary.expand.section.capitalTags')}
                        name={`contentDecoded.${index}.properties.capitalizeTags`}
                    />
                    <Switch
                        label={t('publication.settings.content.summary.expand.section.capitalizeTagsInList')}
                        name={`contentDecoded.${index}.properties.capitalizeTagsInList`}
                    />
                </>
            </PublicationProperty>

            <PublicationProperty property={templateProperties.section.numberingSettings}>
                <div className="table-responsive">
                    <Table className="table-publication-section" style={{ minWidth: 800 }}>
                        <thead>
                            <tr>
                                <th />
                                <th>{t('publication.settings.content.summary.expand.section.prefix')}</th>
                                <th width={200}>
                                    {t('publication.settings.content.summary.expand.section.numbering')}
                                </th>
                                <th>{t('publication.settings.content.summary.expand.section.start')}</th>

                                {html && (
                                    <PublicationProperty property={templateProperties.layout.htmlCollapsible}>
                                        <>
                                            <th className="text-center">
                                                {t(
                                                    'publication.settings.content.summary.expand.section.contentCollapsible',
                                                )}
                                            </th>
                                            <th className="text-center">
                                                {t(
                                                    'publication.settings.content.summary.expand.section.contentCollapsedDefault',
                                                )}
                                            </th>
                                        </>
                                    </PublicationProperty>
                                )}

                                {pdf && (
                                    <>
                                        <th className="text-center">
                                            {t('publication.settings.content.summary.expand.section.contentPdf')}
                                        </th>
                                        <th className="text-center">
                                            {t('publication.settings.content.summary.expand.section.pdfNewPage')}
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {contentItem.sectionLayout.map((_row, sectionLayoutIndex) => (
                                <SectionLayoutRow
                                    row={_row}
                                    contentIndex={index}
                                    sectionLayoutIndex={sectionLayoutIndex}
                                    showTitleInDocument={contentItem['properties']['showTitleInDocument']}
                                    html={html}
                                    pdf={pdf}
                                    key={`sectionLayout-row-${sectionLayoutIndex}`}
                                />
                            ))}
                        </tbody>
                    </Table>
                </div>
            </PublicationProperty>
        </>
    );
}

function SectionLayoutRow({ row, contentIndex, sectionLayoutIndex, showTitleInDocument, html, pdf }) {
    const { t } = useTranslation('publications');
    const disableFormItems = row.id === 'section' && !showTitleInDocument;
    const sectionLayoutRowOptions = {
        sub1: t('publication.settings.content.summary.expand.sectionLayoutRowOptions.chapter'),
        sub2: '1.1',
        sub3: '1.1.1',
        'parent+1': t('publication.settings.content.summary.expand.sectionLayoutRowOptions.above'),
        manual: t('publication.settings.content.summary.expand.sectionLayoutRowOptions.manual'),
        abc: 'ABC',
        off: t('publication.settings.content.summary.expand.sectionLayoutRowOptions.none'),
    };
    const prefixOptions = Object.keys(sectionLayoutRowOptions).map((option) => {
        return {
            label: sectionLayoutRowOptions[option],
            value: option,
        };
    });

    const sectionLayoutRows = {
        section: 'publication.settings.content.summary.expand.chapter',
        heading2: 'H2',
        heading3: 'H3',
        heading4: 'H4',
        heading5: 'H5',
        heading6: 'H6',
    };

    const { [row.id]: translatedSection = sectionLayoutRows[row.id] } = sectionLayoutRows;

    return (
        <tr>
            <td>
                <div className="col-form-label pb-0" style={{ paddingTop: '0.65rem' }}>
                    {t(`publications:${translatedSection}`)}
                </div>
            </td>
            <td>
                {row.id === 'section' && (
                    <InputField
                        name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.sectionPrefixExtra`}
                        props={{ disabled: disableFormItems }}
                    />
                )}
            </td>
            <td>
                {row.id === 'section' ? (
                    <InputField
                        name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.prefix`}
                        props={{ disabled: disableFormItems }}
                    />
                ) : (
                    <InputSelect
                        name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.prefix`}
                        options={prefixOptions}
                    />
                )}
            </td>
            <td>
                {row.id !== 'section' && (
                    <InputField
                        name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.prefixStart`}
                        props={{ disabled: row.prefix !== 'abc' }}
                    />
                )}
            </td>

            {html && (
                <PublicationProperty property={templateProperties.layout.htmlCollapsible}>
                    <>
                        <td className="center-align-checkbox">
                            <InputCheck
                                name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.contentCollapsable`}
                                props={{ disabled: disableFormItems }}
                            />
                        </td>
                        <td className="center-align-checkbox">
                            <InputCheck
                                name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.defaultCollapsed`}
                                props={{ disabled: disableFormItems || !row.contentCollapsable }}
                            />
                        </td>
                    </>
                </PublicationProperty>
            )}

            {pdf && (
                <>
                    <td className="center-align-checkbox">
                        <InputCheck
                            name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.tableOfContents`}
                            props={{ disabled: disableFormItems }}
                        />
                    </td>
                    <td className="center-align-checkbox">
                        <InputCheck
                            name={`contentDecoded.${contentIndex}.sectionLayout.${sectionLayoutIndex}.startOnNewPage`}
                        />
                    </td>
                </>
            )}
        </tr>
    );
}
