import React, { Component, useState } from 'react';
import Language from '../../language/Language';
import HelperFunctions from '../global/HelperFunctions';
import { animateScroll as scroll } from 'react-scroll';
import LoadingSpinner from '../global/LoadingSpinner';
import ReimbursementApi from '../../api/ReimbursementApi';
import { DateTime } from 'luxon';
import deepcopy from 'deepcopy';
import ProductHelper from '../global/ProductHelper';
import _ from 'lodash';
import RestrictedReimbursementContent from '../reimbursements/RestrictedReimbursementContent';
import Constants from '../../config/Constants';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { batchEditReimbursements } from 'features/reimbursements/reimbursementSlice';
import ProductForm from './forms/ProductForm';
import { BASE_PATH, VIEW_PATH } from 'scenes/Reimbursements';
import SortableProducts from './views/SortableProducts';
import cx from 'classnames';
import { Col, Container, Dropdown, Row, SplitButton } from 'react-bootstrap';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { DragDropContext } from 'react-beautiful-dnd';
import { reimbursementApi } from 'features/reimbursements/reimbursements';
import AssignLabelButton from '../labels/AssignLabelButton';
import MainContentNav from '../Navbar';
import SubNav from '../reimbursements/views/SubNav';
import { useGetCategory } from 'features/reimbursements/reimbursementHooks';
import { InfoCircle, Link45deg } from 'react-bootstrap-icons';
import { CloneProductModal } from './CloneProductModal';
import { useTranslation } from 'react-i18next';

export default function UpdateProductsAsFunction() {
    const params = useParams();
    const categoryId = parseInt(params.categoryId);
    const dispatch = useDispatch();
    const { category } = useGetCategory(categoryId);
    const [productToClone, setProductToClone] = useState(undefined);
    const { t } = useTranslation('reimbursements');

    const editProducts = (changes) => {
        dispatch(
            batchEditReimbursements({
                formData: {
                    products: changes,
                },
            }),
        );
    };

    const invalidateTags = (tags = []) => {
        dispatch(reimbursementApi.util.invalidateTags(tags));
    };

    if (category === undefined) {
        return <LoadingSpinner />;
    }

    return (
        <RestrictedReimbursementContent
            categoryId={categoryId}
            roles={[
                Constants.reimbursementTeamRoles.admin,
                Constants.reimbursementTeamRoles.manager,
                Constants.reimbursementTeamRoles.finalEditor,
            ]}
        >
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    { title: category?.name, url: generatePath(VIEW_PATH, { categoryId }) },
                    { title: t('policy.title') },
                ]}
            />

            <SubNav />

            <div className="w-100">
                <div className="content-static-body has-subnav">
                    <div className="d-flex align-items-stretch h-100">
                        <div id="scroll-container" className="flex-grow-1 overflow-auto pt-5 pb-3">
                            <UpdateProducts
                                category={category}
                                editProducts={editProducts}
                                invalidateTags={invalidateTags}
                                productToClone={productToClone}
                                setProductToClone={setProductToClone}
                                t={t}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </RestrictedReimbursementContent>
    );
}

class UpdateProducts extends Component {
    constructor(props) {
        super(props);

        this.categoryId = this.props.category.id;
        this.requiredFormFields = ['name', 'code'];
        this.state = {
            changesSaved: false,
            disableSubmitBtn: true,
            category: {
                name: '',
                products: [],
                productTypes: [],
            },
            editProductId: null,
            formData: this.getFormData(),
            isLoading: true,
            saveInProgress: false,
        };
    }

    addProduct = (formData) => {
        if (formData.hasOwnProperty('id')) {
            delete formData.id;
        }

        return new Promise((resolve, reject) => {
            ReimbursementApi.createProduct(formData)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    cancelEdit = () => {
        this.setState({
            disableSubmitBtn: true,
            editProductId: null,
            formData: this.getFormData(),
        });
    };

    changesSaved = () => {
        this.setState(
            {
                changesSaved: true,
                disableSubmitBtn: true,
                editProductId: null,
                formData: this.getFormData(),
                saveInProgress: false,
            },
            () => {
                HelperFunctions.changesSaved().then(() => {
                    this.setState({ changesSaved: false });
                });
            },
        );
    };

    cloneItem = (item) => {
        const newItem = {
            name: item.name + ' (' + Language.getTranslation('copy', 'text', 'products') + ')',
            abbreviation: item.abbreviation,
            description: item.description,
            category: item.category,
            code: item.code + '-' + Language.getTranslation('copy', 'text', 'products'),
            enabled: item.enabled,
            productType: item.productType ? item.productType : null,
            parent: item.parent ? item.parent : null,
            startDate: item.startDate ? item.startDate : null,
            endDate: item.endDate ? item.endDate : null,
            identicalTo: item.identicalTo ? item.identicalTo : null,
            relatedProducts: item.relatedProducts,
            sortOrder: item.sortOrder + 1,
        };
        const t = this.props.t;

        this.addProduct(newItem)
            .then(() => {
                ReimbursementApi.getProducts(this.categoryId).then((data) => {
                    this.setState({
                        category: {
                            ...this.state.category,
                            products: data['hydra:member'].filter((product) => product.deletedAt === null),
                        },
                    });
                });
            })
            .catch((exception) => {
                if (exception.errorCode === 422 && exception.data['hydra:description'] === 'UniqueCodeViolation') {
                    this.setState({
                        saveInProgress: false,
                    });

                    HelperFunctions.alertModal(t('policy.errorMessage.codeError'));
                }
            });
    };

    componentDidMount() {
        new Promise((resolve) => {
            ReimbursementApi.getProducts(this.categoryId)
                .then((data) => {
                    const category = {
                        ...this.props.category,
                        products: data['hydra:member'].filter((product) => product.deletedAt === null),
                    };

                    resolve(category);
                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }).then((category) => {
            ReimbursementApi.getProductTypes(this.categoryId).then((data) => {
                category = {
                    ...category,
                    productTypes: data['hydra:member'],
                };

                this.setState({
                    category,
                    isLoading: false,
                });
            });
        });
    }

    editProduct = (product) => {
        scroll.scrollToBottom({
            containerId: 'scroll-container',
        });

        const formData = {
            '@id': product['@id'],
            id: product.id,
            name: product.name,
            description: product.description,
            abbreviation: product.abbreviation ?? '',
            code: product.code,
            productType: product.productType ? product.productType : null,
            parent: product.parent ? product.parent : null,
            identicalTo: product.identicalTo ? product.identicalTo : null,
            startDate: product.startDate ? product.startDate : null,
            endDate: product.endDate ? product.endDate : null,
            relatedProducts: product.relatedProducts ? product.relatedProducts : [],
            extendedProducts: product.extendedProducts ? product.extendedProducts : [],
            productGroup: product.productGroup,
        };

        this.setState({
            disableSubmitBtn: false,
            editProductId: product.id,
            formData,
        });
    };

    getFormData = () => {
        return {
            id: null,
            category: '/api/categories/' + this.categoryId,
            name: '',
            productType: null,
            productGroup: false,
            parent: null,
            identicalTo: null,
            description: '',
            abbreviation: '',
            startDate: null,
            endDate: null,
            code: '',
            relatedProducts: [],
            extendedProducts: [],
        };
    };

    handleFormChange = (event, index) => {
        const formData = this.state.formData;
        const target = event.target;
        let value = target.name === 'productGroup' ? target.checked : target.value;

        if (target.name === 'startDate' || target.name === 'endDate') {
            value = DateTime.fromISO(target.value).toFormat('yyyy-MM-dd');

            formData[target.name] = value;
        } else {
            // Update data
            formData[target.name] = value;
        }

        this.setState({
            formData,
            disableSubmitBtn: !HelperFunctions.formIsValid(formData, this.requiredFormFields),
        });
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        const t = this.props.t;

        this.setState(
            {
                disableSubmitBtn: true,
                saveInProgress: true,
            },
            () => {
                const formData = deepcopy(this.state.formData);
                const extendedProducts = formData.extendedProducts;
                delete formData.extendedProducts;

                return new Promise((resolve, reject) => {
                    if (formData.id === null) {
                        // Find new sort order
                        let sortOrder = 0;
                        const currentProducts = this.state.category.products
                            .filter((_product) => _product.parent === (formData.parent === '' ? null : formData.parent))
                            .sort(HelperFunctions.dynamicSort('sortOrder'));

                        if (currentProducts.length > 0) {
                            sortOrder = currentProducts[currentProducts.length - 1]['sortOrder'] + 1;
                        }

                        // Set sort order
                        formData.sortOrder = sortOrder;

                        // Add new item
                        this.addProduct(formData)
                            .then((data) => {
                                resolve(data);
                            })
                            .catch((exception) => {
                                reject(exception);
                            });
                    } else {
                        // Update item
                        this.updateProduct(formData)
                            .then((data) => {
                                resolve(data);
                            })
                            .catch((exception) => {
                                reject(exception);
                            });
                    }
                })
                    .then(async (result) => {
                        if (extendedProducts.length > 0) {
                            for (const extendedProduct of extendedProducts) {
                                // ExtendedProduct already existed before
                                if (extendedProduct.hasOwnProperty('id')) {
                                    if (extendedProduct.remove) {
                                        // Delete
                                        await ReimbursementApi.deleteProductLink(extendedProduct.id);
                                    } else {
                                        // Update
                                        await ReimbursementApi.updateProductLink(extendedProduct.id, extendedProduct);
                                    }
                                } else {
                                    // Insert
                                    await ReimbursementApi.createProductLink(extendedProduct);
                                }
                            }
                        }

                        // Invalidate Tags
                        this.props.invalidateTags([{ type: 'Product', id: 'LIST' }]);

                        // Reload products
                        await ReimbursementApi.getProducts(this.categoryId).then((data) => {
                            this.setState({
                                category: {
                                    ...this.state.category,
                                    products: data['hydra:member'].filter((product) => product.deletedAt === null),
                                },
                            });

                            this.changesSaved();
                        });
                    })
                    .catch(({ response }) => {
                        if (response.status === 422 && response.data['hydra:description'] === 'UniqueCodeViolation') {
                            this.setState({
                                disableSubmitBtn: false,
                                saveInProgress: false,
                            });

                            HelperFunctions.alertModal(t('policy.errorMessage.codeError'));
                        }
                    });
            },
        );
    };

    reloadProducts = () => {
        return ReimbursementApi.getProducts(this.categoryId).then((data) => {
            this.setState({
                category: {
                    ...this.state.category,
                    products: data['hydra:member'].filter((product) => product.deletedAt === null),
                },
            });
        });
    };

    handleTagAddition = (tag) => {
        const formData = this.state.formData;
        formData.relatedProducts = [...formData.relatedProducts, tag];

        this.setState({ formData });
    };

    addRelatedProduct = (baseProductIRI, extendedProductIRI) => {
        const existingItemIndex = _.findIndex(this.state.formData.extendedProducts, {
            baseProduct: baseProductIRI,
            extendedProduct: extendedProductIRI,
        });

        let newExtendedProducts = _.cloneDeep(this.state.formData.extendedProducts);

        const newProductLinkData = {
            baseProduct: baseProductIRI,
            extendedProduct: extendedProductIRI,
        };

        // the extendedProduct to be added does not exist in array yet. Simply add it to the array
        if (existingItemIndex <= -1) {
            newExtendedProducts = [...newExtendedProducts, newProductLinkData];
        } else {
            // the extendedProduct to be added already exists in the array, but wasn't shown because it was marked to be removed.
            // Reset it's values and delete the 'remove marker'.
            newExtendedProducts[existingItemIndex] = { ...newExtendedProducts[existingItemIndex], newProductLinkData };
            delete newExtendedProducts[existingItemIndex].remove;
        }

        this.setState({
            formData: {
                ...this.state.formData,
                extendedProducts: newExtendedProducts,
            },
        });
    };

    removeRelatedProduct = (productLinkToRemove) => {
        const formData = _.cloneDeep(this.state.formData);

        // ProductLink is new and hasn't been saved yet
        if (!_.has(productLinkToRemove, '@id')) {
            // Simply remove it from the array so it won't be persisted on save.
            _.remove(formData.extendedProducts, {
                baseProduct: productLinkToRemove.baseProduct,
                extendedProduct: productLinkToRemove.extendedProduct,
            });
        } else {
            // ProductLink has already been saved. Mark is with 'remove = true' so it can be deleted upon submit
            const index = _.findIndex(formData.extendedProducts, ['@id', productLinkToRemove['@id']]);
            if (index !== -1) {
                formData.extendedProducts[index].remove = true;
            }
        }

        this.setState({ formData });
    };

    handleTagDelete = (i) => {
        const formData = this.state.formData;
        formData.relatedProducts = formData.relatedProducts.filter((tag, index) => index !== i);

        this.setState({ formData });
    };

    moveDown = (parentUri, index) => {
        this.handleMove(parentUri, index, index + 1);
    };

    moveUp = (parentUri, index) => {
        this.handleMove(parentUri, index, index - 1);
    };

    handleMove = (parentUri, fromIndex, toIndex) => {
        const currentProducts = this.state.category.products
            .filter((_product) => _product.parent === parentUri)
            .sort(HelperFunctions.dynamicSort('sortOrder'));

        let newProducts = HelperFunctions.arrayMove(currentProducts, fromIndex, toIndex);

        this.saveSortOrder(newProducts);
    };

    saveSortOrder = (products = []) => {
        // Reset sort order
        const newProducts = products.map((_product, index) => {
            return {
                ..._product,
                sortOrder: index,
            };
        });

        // Save in state
        this.setState({
            category: {
                ...this.state.category,
                products: this.state.category.products.map((_product) => {
                    return _.find(newProducts, ['id', _product.id]) ?? _product;
                }),
            },
        });

        // Save in API
        this.props.editProducts(
            newProducts.map((_product) => {
                return {
                    idAsInt: _product.id,
                    id: _product['@id'],
                    changes: {
                        sortOrder: _product.sortOrder,
                        parent: _product.parent,
                    },
                };
            }),
        );
    };

    toggleEnable = (product) => {
        const updateProduct = {
            id: product.id,
            enabled: !product.enabled,
        };

        this.updateProduct(updateProduct)
            .then((data) => {
                const products = this.state.category.products.map((product) => {
                    return product.id === updateProduct.id ? data : product;
                });
                const t = this.props.t;
                this.setState({
                    category: {
                        ...this.state.category,
                        products: products,
                    },
                });

                // Invalidate Tags
                this.props.invalidateTags([{ type: 'Product', id: 'LIST' }]);
            })
            .catch((exception) => {
                if (exception.errorCode === 422 && exception.data['hydra:description'] === 'UniqueCodeViolation') {
                    this.setState({
                        saveInProgress: false,
                    });

                    HelperFunctions.alertModal(t('policy.errorMessage.codeError'));
                }
            });
    };

    updateProduct = (formData) => {
        if (formData.hasOwnProperty('parent') && formData.parent === '') {
            formData.parent = null;
        }

        if (formData.hasOwnProperty('productType') && formData.productType === '') {
            formData.productType = null;
        }

        if (formData.hasOwnProperty('identicalTo') && formData.identicalTo === '') {
            formData.identicalTo = null;
        }

        return new Promise((resolve, reject) => {
            ReimbursementApi.updateProduct(formData.id, formData)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    onDragEnd = (result) => {
        const { source, destination } = result;

        if (
            destination === null ||
            (source.droppableId === destination.droppableId && source.index === destination.index)
        ) {
            return;
        }

        let newProducts = [];

        if (source.droppableId !== destination.droppableId) {
            // Remove from source
            const sourceProducts = this.state.category.products
                .filter((_product) => _product.parent === source.droppableId)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            const destinationProducts = this.state.category.products
                .filter((_product) => _product.parent === destination.droppableId)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            // Update parent
            sourceProducts[source.index]['parent'] = destination.droppableId;

            // Insert into destination
            destinationProducts.splice(destination.index, 0, sourceProducts[source.index]);
            newProducts = destinationProducts;
        }

        if (source.droppableId === destination.droppableId) {
            // Sort within group
            const currentProducts = this.state.category.products
                .filter((_product) => _product.parent === destination.droppableId)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            newProducts = HelperFunctions.arrayMove(currentProducts, source.index, destination.index);
        }

        this.saveSortOrder(newProducts);
    };

    render() {
        if (this.state.isLoading) {
            return <LoadingSpinner />;
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <RootProducts
                            category={this.state.category}
                            cloneItem={this.cloneItem}
                            editProduct={this.editProduct}
                            editProductId={this.state.editProductId}
                            moveDown={this.moveDown}
                            moveUp={this.moveUp}
                            toggleEnable={this.toggleEnable}
                            onDragEnd={this.onDragEnd}
                            setProductToClone={this.props.setProductToClone}
                            reloadProducts={this.reloadProducts}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <ProductForm
                            addProduct={this.addProduct}
                            addRelatedProduct={this.addRelatedProduct}
                            cancelEdit={this.cancelEdit}
                            category={this.state.category}
                            changesSaved={this.state.changesSaved}
                            disableSubmitBtn={this.state.disableSubmitBtn}
                            formData={this.state.formData}
                            handleFormChange={this.handleFormChange}
                            handleFormSubmit={this.handleFormSubmit}
                            handleTagAddition={this.handleTagAddition}
                            handleTagDelete={this.handleTagDelete}
                            removeRelatedProduct={this.removeRelatedProduct}
                            saveInProgress={this.state.saveInProgress}
                        />
                    </Col>
                </Row>

                <CloneProductModal
                    product={this.props.productToClone}
                    handleClose={() => this.props.setProductToClone(undefined)}
                    reloadProducts={this.reloadProducts}
                />
            </Container>
        );
    }
}

function RootProducts({
    category,
    cloneItem,
    editProduct,
    editProductId,
    moveDown,
    moveUp,
    toggleEnable,
    onDragEnd,
    setProductToClone,
    reloadProducts,
}) {
    const [isDragging, setDragging] = useState(false);

    const rootProducts = category.products
        .filter((product) => {
            return !product.hasOwnProperty('parent') || product.parent === null;
        })
        .sort(HelperFunctions.dynamicSort('sortOrder'));

    const handleDrag = (result) => {
        setDragging(false);

        // Handle results in parent component
        onDragEnd(result);
    };

    return (
        <DragDropContext onDragEnd={handleDrag} onDragStart={() => setDragging(true)}>
            <div className="data-table data-table-reimbursements mb-4">
                {rootProducts.length > 0 &&
                    rootProducts.map((product, index) => {
                        return (
                            <Product
                                category={category}
                                cloneProduct={cloneItem}
                                editProduct={editProduct}
                                editProductId={editProductId}
                                index={index}
                                moveDown={moveDown}
                                moveUp={moveUp}
                                product={product}
                                toggleEnable={toggleEnable}
                                isDragging={isDragging}
                                isLast={index === rootProducts.length - 1}
                                setProductToClone={setProductToClone}
                                reloadProducts={reloadProducts}
                                key={`product-${product.id}`}
                            />
                        );
                    })}
            </div>
        </DragDropContext>
    );
}

export function Product(props) {
    const {
        category,
        cloneProduct,
        currentLevel = 0,
        editProduct,
        editProductId,
        index,
        moveDown,
        moveUp,
        product,
        toggleEnable,
        provided,
        isDragging,
        isLast,
        setProductToClone,
        reloadProducts,
    } = props;

    const [showEditButtons, setShowEditButtons] = useState(false);
    const { t } = useTranslation('reimbursements');

    const productType = category.productTypes
        ? HelperFunctions.getByValue(category.productTypes, '@id', product.productType)
        : undefined;
    const identicalProduct =
        product.identicalTo !== null
            ? HelperFunctions.getByValue(category.products, '@id', product.identicalTo)
            : undefined;
    let nameClasses = '';

    if (product.productGroup) {
        nameClasses = 'uk-text-bold';

        if (product.enabled) {
            nameClasses += ' uk-text-primary';
        }
    }

    return (
        <>
            <div
                className={cx('data-table-row d-flex pl-3', {
                    'uk-background-muted': product.id === editProductId,
                    'uk-background-theme-light-hover': !isDragging,
                })}
                onMouseEnter={() => setShowEditButtons(true)}
                onMouseLeave={() => setShowEditButtons(false)}
            >
                <div className={cx('d-flex align-items-center', { 'uk-text-muted': !product.enabled })}>
                    {currentLevel > 0 && (
                        <div className="d-flex align-items-center icon-grip mr-1 ml-n2" {...provided.dragHandleProps}>
                            <RxDragHandleDots2 size={12} className="text-muted" />
                        </div>
                    )}

                    {[...Array(currentLevel)].map((x, y) => (
                        <div className="uk-padding-small-right" key={y}>
                            --
                        </div>
                    ))}
                    {product.code && (
                        <div className="uk-text-small uk-text-muted uk-padding-small-right">{product.code}</div>
                    )}
                    <div className={nameClasses}>{product.name}</div>

                    {ProductHelper.isLinkedProduct(product) && (
                        <Link45deg className="ml-1" data-uk-tooltip="Product heeft collectieve aanvullingen" />
                    )}

                    {productType && (
                        <div className="uk-text-xsmall">
                            &nbsp;({productType.code && <span className="uk-text-muted">{productType.code} </span>}
                            {productType.name})
                        </div>
                    )}

                    {!product.enabled && <div className="uk-text-xsmall">{t('policy.tooltip.inactive')}</div>}

                    {product.abbreviation && (
                        <div
                            className="uk-padding-left uk-text-xsmall uk-text-muted uk-text-italic"
                            data-uk-tooltip={t('policy.tooltip.abbreviation')}
                        >
                            {product.abbreviation}
                        </div>
                    )}

                    {product.description && (
                        <div
                            className="uk-padding-left uk-text-xsmall uk-text-muted uk-text-italic"
                            data-uk-tooltip={t('policy.tooltip.description')}
                        >
                            {product.description}
                        </div>
                    )}

                    {identicalProduct !== undefined && (
                        <div className="uk-padding-left uk-text-xsmall uk-text-muted uk-text-italic">
                            <InfoCircle className="mr-1" />
                            {t('reimbursement.product.misc.identical', {
                                identicalCode: identicalProduct.code,
                                identicalName: identicalProduct.name,
                            })}
                        </div>
                    )}

                    {showEditButtons && (
                        <div className="uk-animation-fade uk-animation-fast ml-2">
                            <AssignLabelButton
                                context={{
                                    entity: {
                                        ...product,
                                        title: product.name,
                                    },
                                    entityType: 'product',
                                }}
                            />
                        </div>
                    )}
                </div>

                <div className="ml-auto">
                    <EditProductButton
                        product={product}
                        editProduct={editProduct}
                        setProductToClone={setProductToClone}
                        toggleEnable={toggleEnable}
                        reloadProducts={reloadProducts}
                    />

                    <button
                        type="button"
                        disabled={index === 0}
                        className="uk-button uk-margin-left"
                        onClick={() => moveUp(product.parent, index)}
                        uk-icon="icon: arrow-up; ratio: 1.1"
                    />
                    <button
                        type="button"
                        disabled={isLast}
                        className="uk-button"
                        onClick={() => moveDown(product.parent, index)}
                        uk-icon="icon: arrow-down; ratio: 1.1"
                    />
                </div>
            </div>

            {(product.children.length > 0 || currentLevel === 0) && (
                <SortableProducts
                    parent={product}
                    category={category}
                    cloneProduct={cloneProduct}
                    currentLevel={currentLevel}
                    editProductId={editProductId}
                    editProduct={editProduct}
                    moveDown={moveDown}
                    moveUp={moveUp}
                    toggleEnable={toggleEnable}
                    isDragging={isDragging}
                    setProductToClone={setProductToClone}
                    reloadProducts={reloadProducts}
                />
            )}
        </>
    );
}

function EditProductButton({ product, editProduct, toggleEnable, setProductToClone, reloadProducts }) {
    const { t } = useTranslation('reimbursements');

    return (
        <SplitButton
            id={`product-${product.id}-options-btn`}
            variant="primary"
            size="sm"
            title={t('policy.btn.edit')}
            onClick={() => editProduct(product)}
            onSelect={handleSelect}
            alignRight={true}
        >
            <Dropdown.Item eventKey="duplicate" disabled={!product.enabled || product.productGroup}>
                {t('policy.btn.duplicate')}&hellip;
            </Dropdown.Item>
            <Dropdown.Item eventKey="toggle">
                <span
                    className={cx({
                        // 'text-danger': product.enabled,
                        'text-success': !product.enabled,
                    })}
                >
                    {product.enabled ? t('policy.btn.disable') : t('policy.btn.enable')}
                </span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
                eventKey="delete"
                disabled={
                    product.productGroup && product.children.filter((product) => product.deletedAt === null).length > 0
                }
            >
                <span className="text-danger">{t('global:btn.delete')}&hellip;</span>
            </Dropdown.Item>
        </SplitButton>
    );

    function handleSelect(eventKey) {
        switch (eventKey) {
            case 'duplicate':
                setProductToClone(product);
                return;
            case 'toggle':
                toggleEnable(product);
                return;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('global:confirm.confirmDelete', { name: product.name })}`,
                    'danger',
                    false,
                    t('global:confirm.yesDelete'),
                    t('global:btn.cancel'),
                )
                    .then(() => {
                        ReimbursementApi.deleteProduct(product.id)
                            .then(() => {
                                reloadProducts();
                            })
                            .catch((result) => {
                                HelperFunctions.alertModal(
                                    result.response?.data['hydra:description'] ?? result.message,
                                );
                            });
                    })
                    .catch(() => {});
        }
    }
}
