import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { set } from 'features/app/appSlice';
import { BlockImprovementModal } from '../../modals/BlockImprovementModal';
import { LinkedBlockModal } from '../../modals/LinkedBlockModal';
import { EditPackageReimbursementModal } from 'pages/documents/misc/_EditArea/EditPackageReimbursementModal';
import { useGetDocument } from '../../hooks/useGetDocument';
import HelperFunctions from '../../../global/HelperFunctions';
import { ShowLinkedBlocksModal } from '../../modals/ShowLinkedBlocksModal';
import { PreviousBlockVersionsModal } from '../../modals/PreviousBlockVersionsModal';
import { EditTagModal } from '../../modals/EditTagModal';

export function BlockEditModals() {
    const dispatch = useDispatch();
    const { blockEditModal } = useSelector((state) => state.app);
    const { setFieldValue } = useFormikContext();
    const document = useGetDocument();

    const closeModal = () => {
        dispatch(
            set({
                key: 'blockEditModal',
                value: undefined,
            }),
        );
    };

    if (blockEditModal === undefined) {
        return null;
    }

    const { eventKey, block, blockIndex } = blockEditModal;

    if (eventKey === 'blockImprovementModal') {
        return (
            <BlockImprovementModal
                block={block}
                handleClose={() => closeModal()}
                handleBlockChange={(newValue) => {
                    setFieldValue(`areaBlocks.${blockIndex}.latestContent`, newValue);
                }}
            />
        );
    }

    if (eventKey === 'linkedBlockModal') {
        return (
            <LinkedBlockModal
                block={block}
                handleClose={() => closeModal()}
                handleBlockChange={(newValue) => {
                    setFieldValue(`areaBlocks.${blockIndex}.latestContent`, newValue);
                    closeModal();
                }}
            />
        );
    }

    if (eventKey === 'previousBlockVersions') {
        return <PreviousBlockVersionsModal block={block} handleClose={() => closeModal()} />;
    }

    if (eventKey === 'showLinkedBlockModal') {
        return <ShowLinkedBlocksModal block={block} handleClose={() => closeModal()} />;
    }

    if (eventKey === 'reimbursementBlockModal') {
        const blockData = HelperFunctions.tryParseJSON(block.latestContent);

        return (
            <EditPackageReimbursementModal
                showModal={true}
                handleClose={() => closeModal()}
                handleFormSubmit={(code) => {
                    setFieldValue(
                        `areaBlocks.${blockIndex}.latestContent`,
                        JSON.stringify({
                            data: [],
                            code,
                        }),
                    );
                    closeModal();
                }}
                categoryId={document.reimbursementCategoryId}
                formData={{
                    id: block.id,
                    code: blockData?.code ?? '',
                }}
            />
        );
    }

    if (eventKey === 'editTagModal') {
        return (
            <EditTagModal
                tag={blockEditModal.tag}
                handleClose={() => closeModal()}
                filterByVariants={block.documentVariants}
            />
        );
    }

    return null;
}
