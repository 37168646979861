import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';

const baseUrl = getEnv('REIMBURSEMENT_API_BASE_URL');
const uriPrefix = '/api/reimbursement-api/api';

const reimbursementApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const reimbursementApi = createApi({
    reducerPath: 'reimbursementApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, reimbursementApiQuery),
    tagTypes: [
        'Category',
        'CategoryGroup',
        'Reimbursement',
        'Product',
        'ReimbursementProduct',
        'ReimbursementProductCode',
        'ReimbursementCode',
        'History',
        'EntityHistory',
        'ReimbursementProductField',
        'Change',
        'ProductCoverage',
        'ProductType',
        'Proposition',
        'PropositionGroup',
        'CollectiveGroup',
        'Collective',
    ],
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => `${uriPrefix}/categories`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Category'),
        }),
        getCategoryGroups: builder.query({
            query: () => `${uriPrefix}/category_groups`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'CategoryGroup'),
        }),
        addCategoryGroup: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/category_groups`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'CategoryGroup', id: 'LIST' }],
        }),
        updateCategoryGroup: builder.mutation({
            query: (data) => {
                const { uri, body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'CategoryGroup', id: arg.uri }],
        }),
        deleteCategoryGroup: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'CategoryGroup', id: arg.uri }],
        }),
        editCategories: builder.mutation({
            query: (changes) => ({
                url: `${uriPrefix}/batch_edits`,
                method: 'POST',
                body: {
                    categories: changes,
                },
            }),
            invalidatesTags: () => [
                { type: 'Category', id: 'LIST' },
                { type: 'CategoryGroup', id: 'LIST' },
            ],
            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     const patchResult = dispatch(
            //         publicationApi.util.updateQueryData('getPublications', undefined, (draft) => {
            //             draft.forEach((publication) => {
            //                 const publicationPatch = body.find((patch) => patch.id === publication.id);
            //
            //                 if (publicationPatch) {
            //                     Object.assign(publication, {
            //                         ...publication,
            //                         ...publicationPatch.changes,
            //                     });
            //                 }
            //             });
            //         }),
            //     );
            //     try {
            //         await queryFulfilled;
            //     } catch {
            //         patchResult.undo();
            //     }
            // },
        }),
        updateCategory: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Category', id: arg.uri }],
        }),
        updateCategoryEdits: builder.mutation({
            query: (uri) => {
                return {
                    url: `${uri}/update_edits`,
                    method: 'PUT',
                    body: {},
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'Category', id: arg },
                {
                    type: 'Reimbursement',
                },
                {
                    type: 'Product',
                    id: 'LIST',
                },
                {
                    type: 'ReimbursementProduct',
                    id: 'LIST',
                },
            ],
        }),
        addCategory: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/categories`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'Category', id: 'LIST' }],
        }),
        migrateCategoryFields: builder.mutation({
            query: (uri) => {
                return {
                    url: `${uri}/migrate_fields`,
                    method: 'PUT',
                    body: [],
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Category', id: arg }],
        }),
        getProductTypes: builder.query({
            query: (uri) => `${uri}/product_types`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'ProductType'),
        }),
        getReimbursements: builder.query({
            query: (uri) => `${uri}/reimbursements`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Reimbursement'),
        }),
        getReimbursement: builder.query({
            query: (id) => `${uriPrefix}/reimbursements/${id}`,
            providesTags: (result) => [{ type: 'Reimbursement', id: result['@id'] }],
        }),
        updateReimbursement: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                dispatch(
                    reimbursementApi.util.updateQueryData('getReimbursements', patch.category, (draft) => {
                        const index = draft.findIndex((_item) => _item['@id'] === patch.uri);
                        if (index !== -1) {
                            if (patch.hasOwnProperty('disabledProducts')) {
                                Object.assign(draft[index], {
                                    disabledProducts: patch.disabledProducts,
                                });
                            } else {
                                // const { code, enabled, name, description, teams, productTeams } = patch;
                                Object.assign(draft[index], patch);
                            }
                        }
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(reimbursementApi.util.invalidateTags([{ type: 'Reimbursement', id: id }]));
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Reimbursement', id: arg.uri }],
        }),
        updateReimbursementChanges: builder.mutation({
            query: (data) => {
                const { id, ...body } = data;
                return {
                    url: `${uriPrefix}/reimbursements/${id}/process_changes`,
                    method: 'PUT',
                    body: {
                        changes: body,
                    },
                };
            },
            invalidatesTags: (result) => [
                { type: 'Reimbursement', id: result['@id'] },
                { type: 'ReimbursementProduct', id: 'LIST' },
                { type: 'ReimbursementProductCode', id: 'LIST' },
                { type: 'ReimbursementProductField', id: 'LIST' },
                { type: 'History', id: 'LIST' },
                { type: 'EntityHistory' },
                { type: 'Change' },
            ],
        }),
        updateBatchReimbursementAcceptChanges: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: `${uri}/bulk_accept`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result) => [
                { type: 'Reimbursement', id: result['@id'] },
                { type: 'ReimbursementProduct', id: 'LIST' },
                { type: 'ReimbursementProductCode', id: 'LIST' },
                { type: 'ReimbursementProductField', id: 'LIST' },
                { type: 'History', id: 'LIST' },
                { type: 'EntityHistory' },
                { type: 'Change' },
            ],
        }),
        updateBatchReimbursements: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/batch_edits`,
                    method: 'POST',
                    body,
                };
            },
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const {
                    uri,
                    reimbursements = [],
                    collectives = [],
                    collectiveGroups = [],
                    propositions = [],
                    propositionGroups = [],
                } = body;

                if (reimbursements.length > 0) {
                    dispatch(
                        reimbursementApi.util.updateQueryData('getReimbursements', uri, (draft) => {
                            reimbursements.forEach((_item) => {
                                const index = draft.findIndex((_draft) => _draft['@id'] === _item.id);
                                if (index !== -1) {
                                    Object.assign(draft[index], _item.changes);
                                }
                            });
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        dispatch(reimbursementApi.util.invalidateTags([{ type: 'Reimbursement', id: 'LIST' }]));
                    }
                }

                if (collectives.length > 0) {
                    dispatch(
                        reimbursementApi.util.updateQueryData('getCollectives', { uri }, (draft) => {
                            collectives.forEach((_item) => {
                                const index = draft.findIndex((_draft) => _draft['@id'] === _item.id);
                                if (index !== -1) {
                                    Object.assign(draft[index], _item.changes);
                                }
                            });
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        dispatch(reimbursementApi.util.invalidateTags([{ type: 'Collective', id: 'LIST' }]));
                    }
                }

                if (collectiveGroups.length > 0) {
                    dispatch(
                        reimbursementApi.util.updateQueryData('getCollectiveGroups', uri, (draft) => {
                            collectiveGroups.forEach((_item) => {
                                const index = draft.findIndex((_draft) => _draft['@id'] === _item.id);
                                if (index !== -1) {
                                    Object.assign(draft[index], _item.changes);
                                }
                            });
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        dispatch(reimbursementApi.util.invalidateTags([{ type: 'CollectiveGroup', id: 'LIST' }]));
                    }
                }

                if (propositions.length > 0) {
                    dispatch(
                        reimbursementApi.util.updateQueryData('getPropositions', { uri }, (draft) => {
                            propositions.forEach((_item) => {
                                const index = draft.findIndex((_draft) => _draft['@id'] === _item.id);
                                if (index !== -1) {
                                    Object.assign(draft[index], _item.changes);
                                }
                            });
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        dispatch(reimbursementApi.util.invalidateTags([{ type: 'Proposition', id: 'LIST' }]));
                    }
                }

                if (propositionGroups.length > 0) {
                    dispatch(
                        reimbursementApi.util.updateQueryData('getPropositionGroups', uri, (draft) => {
                            propositionGroups.forEach((_item) => {
                                const index = draft.findIndex((_draft) => _draft['@id'] === _item.id);
                                if (index !== -1) {
                                    Object.assign(draft[index], _item.changes);
                                }
                            });
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        dispatch(reimbursementApi.util.invalidateTags([{ type: 'PropositionGroup', id: 'LIST' }]));
                    }
                }
            },
        }),
        addReimbursement: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/reimbursements`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'Reimbursement', id: 'LIST' }],
        }),
        getReimbursementProduct: builder.query({
            query: (id) => `${uriPrefix}/reimbursement_products/${id}`,
            providesTags: (result) => [{ type: 'ReimbursementProduct', id: result['@id'] }],
        }),
        addReimbursementProduct: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/reimbursement_products`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (result) => [
                { type: 'ReimbursementProduct', id: 'LIST' },
                { type: 'ReimbursementProductCode', id: 'LIST' },
                { type: 'ReimbursementProductField', id: 'LIST' },
                { type: 'History', id: 'LIST' },
                { type: 'ProductCoverage', id: result.reimbursement },
                { type: 'Reimbursement', id: result.reimbursement },
            ],
        }),
        updateReimbursementProduct: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'ReimbursementProduct', id: arg.uri },
                { type: 'ReimbursementProductCode', id: 'LIST' },
                { type: 'ReimbursementProductField', id: 'LIST' },
                { type: 'EntityHistory', id: arg.uri },
                { type: 'History', id: 'LIST' },
                { type: 'ProductCoverage', id: result.reimbursement },
                { type: 'Reimbursement', id: result.reimbursement },
                { type: 'Change', id: arg.uri },
            ],
        }),
        getReimbursementProducts: builder.query({
            query: (uri) => `${uri}/reimbursement_products`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'ReimbursementProduct'),
        }),
        getReimbursementProductCodes: builder.query({
            query: (uri) => `${uri}/reimbursement_product_codes`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'ReimbursementProductCode'),
        }),
        getReimbursementProductFields: builder.query({
            query: (uri) => `${uri}/reimbursement_product_fields`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'ReimbursementProductField'),
        }),
        getReimbursementProductChanges: builder.query({
            query: (uri) => `${uri}/locale/nl/changes`,
            transformResponse: (response) => response['changes'],
            providesTags: (result, error, arg) => [{ type: 'Change', id: arg }],
        }),
        getReimbursementCodes: builder.query({
            query: (uri) => `${uri}/reimbursement_codes`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'ReimbursementCode'),
        }),
        getProducts: builder.query({
            query: (id) => `${uriPrefix}/categories/${id}/products`,
            transformResponse: (response) => response['hydra:member'].filter((product) => product.deletedAt === null),
            providesTags: (result) => providesList(result, 'Product'),
        }),
        getProductCoverage: builder.query({
            query: ({ reimbursementId, productId }) =>
                `${uriPrefix}/reimbursements/${reimbursementId}/products/${productId}/coverage`,
            transformResponse: (response) => response['coverageCount'],
            providesTags: (result, error, arg) => [
                {
                    type: 'ProductCoverage',
                    id: `/api/reimbursement-api/api/reimbursements/${arg.reimbursementId}`,
                },
            ],
        }),
        updateProductEdits: builder.mutation({
            query: ({ reimbursementId, productId }) => ({
                url: `${uriPrefix}/reimbursements/${reimbursementId}/products/${productId}/clear_edits`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Reimbursement', id: arg.reimbursementId },
                { type: 'Product', id: arg.productId },
                { type: 'History' },
                { type: 'EntityHistory' },
                { type: 'Change' },
            ],
        }),
        updateReimbursementEdits: builder.mutation({
            query: ({ reimbursementId }) => ({
                url: `${uriPrefix}/reimbursements/${reimbursementId}/clear_edits`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Reimbursement' }, { type: 'Product' }],
        }),
        getHistory: builder.query({
            query: (params) => ({
                url: `${uriPrefix}/history`,
                params,
            }),
            transformResponse: (response) => response['history'],
            providesTags: () => [{ type: 'History', id: 'LIST' }],
        }),
        getEntityHistory: builder.query({
            query: ({ entityId, entity, historyStartGroupId, historyEndGroupId }) => ({
                url: `${uriPrefix}/histories/entity/${entityId}/${entity}/nl/${historyStartGroupId}/${historyEndGroupId}`,
            }),
            transformResponse: (response) => response['history'],
            providesTags: (result) => [{ type: 'EntityHistory', id: result['@id'] }],
        }),
        getPropositionGroups: builder.query({
            query: (uri) => `${uri}/proposition_groups`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PropositionGroup'),
        }),
        addPropositionGroup: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/proposition_groups`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'PropositionGroup', id: 'LIST' }],
        }),
        updatePropositionGroup: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'PropositionGroup', id: arg.uri }],
        }),
        deletePropositionGroup: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'PropositionGroup', id: arg.uri }],
        }),
        getPropositions: builder.query({
            query: ({ uri, params = [] }) => ({
                url: `${uri}/variant_packages`,
                params,
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Proposition'),
        }),
        getDocumentVariantPropositions: builder.query({
            query: (variantId) => ({
                url: `${uriPrefix}/variant_packages?documentVariantId[]=${variantId}`,
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Proposition'),
        }),
        getProposition: builder.query({
            query: (id) => `${uriPrefix}/variant_packages/${id}`,
            providesTags: (result) => [{ type: 'Proposition', id: result['@id'] }],
        }),
        addProposition: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/variant_packages`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'Proposition', id: 'LIST' }],
        }),
        updateProposition: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Proposition', id: arg.uri }],
        }),
        deleteProposition: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Proposition', id: arg.uri }],
        }),
        getCollectiveGroups: builder.query({
            query: (uri) => `${uri}/collective_groups`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'CollectiveGroup'),
        }),
        addCollectiveGroup: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/collective_groups`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'CollectiveGroup', id: 'LIST' }],
        }),
        updateCollectiveGroup: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'CollectiveGroup', id: arg.uri }],
        }),
        deleteCollectiveGroup: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'CollectiveGroup', id: arg.uri }],
        }),
        getCollectives: builder.query({
            query: ({ uri, params = [] }) => ({
                url: `${uri}/collectives`,
                params,
            }),
            transformResponse: (response) => {
                return response['hydra:member'].map((_item) => ({
                    ..._item,
                    codeAndName: _item.code !== '' ? _item.code + ' ' + _item.name : _item.name,
                }));
            },
            providesTags: (result) => providesList(result, 'Collective'),
        }),
        getCollective: builder.query({
            query: (id) => `${uriPrefix}/collectives/${id}`,
            providesTags: (result) => [{ type: 'Collective', id: result['@id'] }],
        }),
        addCollective: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/collectives`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'Collective', id: 'LIST' },
                { type: 'CollectiveGroup', id: 'LIST' },
            ],
        }),
        updateCollective: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Collective', id: arg.uri }],
        }),
        deleteCollective: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Collective', id: arg.uri }],
        }),
    }),
});

export const {
    useGetCategoriesQuery,
    useGetCategoryGroupsQuery,
    useAddCategoryGroupMutation,
    useUpdateCategoryGroupMutation,
    useDeleteCategoryGroupMutation,
    useEditCategoriesMutation,
    useUpdateCategoryMutation,
    useUpdateCategoryEditsMutation,
    useAddCategoryMutation,
    useMigrateCategoryFieldsMutation,
    useGetProductTypesQuery,
    useGetReimbursementsQuery,
    useGetReimbursementQuery,
    useUpdateReimbursementMutation,
    useUpdateReimbursementChangesMutation,
    useUpdateBatchReimbursementAcceptChangesMutation,
    useUpdateBatchReimbursementsMutation,
    useAddReimbursementMutation,
    useGetReimbursementProductQuery,
    useAddReimbursementProductMutation,
    useUpdateReimbursementProductMutation,
    useGetReimbursementProductsQuery,
    useGetReimbursementProductCodesQuery,
    useGetReimbursementProductFieldsQuery,
    useGetReimbursementProductChangesQuery,
    useGetReimbursementCodesQuery,
    useGetProductsQuery,
    useGetProductCoverageQuery,
    useUpdateProductEditsMutation,
    useUpdateReimbursementEditsMutation,
    useGetHistoryQuery,
    useGetEntityHistoryQuery,
    useGetPropositionGroupsQuery,
    useAddPropositionGroupMutation,
    useUpdatePropositionGroupMutation,
    useDeletePropositionGroupMutation,
    useGetPropositionsQuery,
    useGetDocumentVariantPropositionsQuery,
    useGetPropositionQuery,
    useAddPropositionMutation,
    useUpdatePropositionMutation,
    useDeletePropositionMutation,
    useGetCollectiveGroupsQuery,
    useAddCollectiveGroupMutation,
    useUpdateCollectiveGroupMutation,
    useDeleteCollectiveGroupMutation,
    useGetCollectivesQuery,
    useGetCollectiveQuery,
    useAddCollectiveMutation,
    useUpdateCollectiveMutation,
    useDeleteCollectiveMutation,
} = reimbursementApi;
