import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { LoadingSpinner as LoadingSpinner2 } from '../global/Spinner';
import { WarningButton } from '../../components/Buttons';
import { useGetDocument } from '../documents_v2/hooks/useGetDocument';
import { useGetBaseVariant } from '../documents_v2/hooks/useGetBaseVariant';
import { useGetDocumentVariants } from '../documents_v2/hooks/useGetDocumentVariants';
import { useImmer } from 'use-immer';
import { useAddTagMutation, useGetDocumentTagQuery, useUpdateTagMutation } from '../../features/documents/documents';
import { useActiveOrganisation } from '../../hooks/useActiveOrganisation';
import { TagRow } from './edit/TagRow';
import HelperFunctions from '../global/HelperFunctions';
import { filterDeep } from 'deepdash-es/standalone';
import { useTranslation } from 'react-i18next';
import { QuestionCircle } from 'react-bootstrap-icons';

export function BlocksTagsModal({ documentId, tagId, showModal, closeModal, filterByVariants = [] }) {
    const document = useGetDocument();
    const baseVariant = useGetBaseVariant();
    const documentVariants = useGetDocumentVariants();
    const activeOrganisation = useActiveOrganisation();
    const { t } = useTranslation('documents');

    const [initialItems, setInitialItems] = useState('');
    const [updateTag] = useUpdateTagMutation();
    const [addTag] = useAddTagMutation();

    const { tagData } = useGetDocumentTagQuery(
        { documentId, id: tagId },
        {
            selectFromResult: ({ data }) => ({
                tagData: data,
            }),
            skip: !showModal,
        }
    );

    const [state, setState] = useImmer({
        formData: {
            tagItems: [],
        },
        saveInProgress: false,
        showAllVariants: false,
    });

    const { formData, saveInProgress, showAllVariants } = state;

    const dirty = useMemo(() => {
        if (formData?.tagItems) {
            const currentValues = JSON.stringify(formData.tagItems).replace(':""', ':null');

            return currentValues !== initialItems;
        }

        return false;
    }, [formData?.tagItems]);

    useEffect(() => {
        if (tagData) {
            setState((draft) => {
                draft.formData = tagData;
            });

            setInitialItems(JSON.stringify(tagData.tagItems).replace(':""', ':null'));
        }
    }, [tagData]);

    if (!formData) {
        return null;
    }

    const { tagItems = [], tagValue, isOverride } = formData;

    const baseItem = document
        ? tagItems.find((_item) => _item.documentVariantId === (baseVariant?.id ?? ''))
        : undefined;

    const defaultItem = useMemo(() => {
        return {
            ...tagValue,
            ...baseItem,
        };
    }, [tagValue, baseItem]);

    const updateTagItems = (newItems) => {
        setState((draft) => {
            draft.formData.tagItems = newItems;
        });
    };

    const toggleVariantItem = (checked, variant) => {
        const currentItem = tagItems.find((_item) => _item.documentVariantId === variant.id);

        if (checked === true && currentItem === undefined) {
            // Item not defined yet
            updateTagItems(
                tagItems.concat([
                    {
                        documentVariantId: variant.id,
                        text: defaultItem?.text ?? '',
                        url: defaultItem?.url ?? '',
                        tooltip: defaultItem?.tooltip ?? '',
                        crossReference: defaultItem?.crossReference ?? '',
                    },
                ])
            );

            return;
        }

        // Unchecked
        if (checked === false && currentItem !== undefined) {
            // Remove existing item
            updateTagItems(tagItems.filter((item) => item.documentVariantId !== variant.id));
        }
    };

    const copyItem = (item) => {
        const newTagItems = [item];

        documentVariants.forEach((_variant) => {
            if (_variant.parentId !== null && _variant.enabled === true && _variant.variantGroup === false) {
                const currentItem = tagItems.find((_item) => _item.documentVariantId === _variant.id);
                const itemHasOverride = isOverride && currentItem !== undefined;

                if (
                    (isOverride === false || itemHasOverride === true) &&
                    (filterByVariants.includes(_variant.id) || state.showAllVariants)
                ) {
                    newTagItems.push({
                        documentVariantId: _variant.id,
                        text: item.text,
                        url: item.url,
                        tooltip: item.tooltip,
                        crossReference: item.crossReference,
                    });
                } else {
                    if (currentItem) {
                        newTagItems.push(currentItem);
                    }
                }
            }
        });

        updateTagItems(newTagItems);
    };

    const handleFormChange = (value, propName, variantId) => {
        if (variantId) {
            // Store value for specific Variant
            const index = state.formData.tagItems
                ? state.formData.tagItems.findIndex((item) => item.documentVariantId === variantId)
                : -1;

            if (index === -1) {
                // Create new Variant Tag
                const newVariantTag = {
                    documentVariantId: variantId,
                    text: '',
                    url: '',
                    tooltip: '',
                    crossReference: '',
                };
                newVariantTag[propName] = value;

                setState((draft) => {
                    if (draft.formData.tagItems) {
                        draft.formData.tagItems.push(newVariantTag);
                    } else {
                        draft.formData.tagItems = [newVariantTag];
                    }
                });
            } else {
                // Update existing Variant
                setState((draft) => {
                    draft.formData.tagItems[index][propName] = value;
                });
            }
        } else {
            setState((draft) => {
                if (
                    propName === 'text' ||
                    propName === 'url' ||
                    propName === 'tooltip' ||
                    propName === 'crossReference'
                ) {
                    if (draft.formData.tagValue) {
                        draft.formData.tagValue[propName] = value;
                    } else {
                        draft.formData.tagValue = {
                            [propName]: value,
                        };
                    }
                } else {
                    draft.formData[propName] = value;
                }
            });
        }
    };

    const handleCreateTag = (formData) => {
        if (documentId) {
            formData['document'] = parseInt(documentId);
        }

        return addTag({ organisationId: activeOrganisation, body: formData });
    };

    const handleFormSubmit = (event, baseVariant) => {
        event.preventDefault();
        event.stopPropagation();
        setState((draft) => {
            draft.saveInProgress = true;
        });

        // Create new Tag for Document Tag?
        if (documentId && !state.formData.documentId) {
            handleCreateTag({
                name: state.formData.name,
                isOverride: true,
                tagItems: state.formData.tagItems
                    ? state.formData.tagItems.map((tagItem) => {
                          return {
                              documentVariant: tagItem.documentVariantId,
                              text: tagItem.text,
                              url: tagItem.url,
                              tooltip: tagItem.tooltip,
                              crossReference: tagItem.crossReference,
                          };
                      })
                    : undefined,
            }).then(({ data }) => {
                setState((draft) => {
                    draft.saveInProgress = false;
                    draft.formData = data;
                    draft.selectedItem = [data.id];
                });
            });

            return;
        }

        // Update BasisVariant if !showAllVariants
        if (!showAllVariants) {
            const basisVariantIndex = state.formData.tagItems.findIndex(
                (item) => item.documentVariantId === baseVariant
            );

            if (basisVariantIndex !== -1) {
                setState((draft) => {
                    draft.formData.tagItems[basisVariantIndex].text = state.formData.tagValue.text;
                    draft.formData.tagItems[basisVariantIndex].url = state.formData.tagValue.url;
                    draft.formData.tagItems[basisVariantIndex].tooltip = state.formData.tagValue.tooltip;
                    draft.formData.tagItems[basisVariantIndex].crossReference = state.formData.tagValue.crossReference;
                });
            }
        }

        updateTag({
            organisationId: activeOrganisation,
            id: state.formData.id,
            body: {
                name: state.formData.name,
                description: state.formData.description,
                tagValue: state.formData.tagValue,
                tagItems: state.formData.tagItems
                    ? state.formData.tagItems.map((tagItem) => {
                          return {
                              documentVariant: tagItem.documentVariantId,
                              text: tagItem.text,
                              url: tagItem.url,
                              tooltip: tagItem.tooltip,
                              crossReference: tagItem.crossReference,
                          };
                      })
                    : undefined,
            },
        }).then(() => {
            setState((draft) => {
                draft.saveInProgress = false;
            });
        });
    };

    return (
        <Modal show={showModal} onHide={closeModal} scrollable={true} className="modal-fullscreen">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('document.navbar.main.editor.left.blocks.modals.blocksTagModal.editTag')}:&nbsp;{formData.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="tag-variant-form" autoComplete="off">
                    {filterByVariants.length > 0 && (
                        <Container fluid>
                            <Row>
                                <Col>
                                    <div className="mb-4">
                                        <Form.Switch
                                            checked={state.showAllVariants}
                                            onChange={() =>
                                                setState((draft) => {
                                                    draft.showAllVariants = !draft.showAllVariants;
                                                })
                                            }
                                            id="toggle-variants"
                                            label={t(
                                                'document.navbar.main.editor.left.blocks.modals.blocksTagModal.showAllVariants'
                                            )}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}

                    <DocumentTagForm
                        documentId={documentId}
                        formData={formData}
                        handleFormChange={handleFormChange}
                        toggleVariantItem={toggleVariantItem}
                        copyItem={copyItem}
                        defaultItem={defaultItem}
                        filterByVariants={filterByVariants}
                        showAllVariants={state.showAllVariants}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <LoadingSpinner2 isSubmitting={saveInProgress} />
                <Button variant="link" className="text-muted" onClick={closeModal}>
                    {t('btn.cancel')}
                </Button>
                <WarningButton
                    disabled={saveInProgress || !dirty}
                    type="button"
                    form="tag-variant-form"
                    onClick={handleFormSubmit}
                >
                    {t('btn.save')}
                </WarningButton>
            </Modal.Footer>
        </Modal>
    );
}

function DocumentTagForm({
    documentId,
    formData,
    handleFormChange,
    toggleVariantItem,
    copyItem,
    defaultItem,
    filterByVariants,
    showAllVariants,
}) {
    const documentVariants = useGetDocumentVariants();
    const baseVariant = useGetBaseVariant(undefined, true);
    const { tagItems = [], isOverride } = formData;
    const { t } = useTranslation('documents');

    const filteredDocumentVariants = useMemo(() => {
        if (filterByVariants.length === 0 || showAllVariants) {
            return HelperFunctions.variantAsList(baseVariant);
        }

        const filteredVariants = filterDeep(
            baseVariant,
            (variantValue, key, parent) => {
                if (variantValue.variantGroup) {
                    return undefined;
                }

                return filterByVariants.includes(variantValue.id) ? true : undefined;
            },
            {
                childrenPath: ['children'],
            }
        );

        return HelperFunctions.variantAsList(filteredVariants);
    }, [documentVariants, showAllVariants, filterByVariants]);

    return (
        <Container fluid>
            <Row className="no-gutters font-weight-bold text-secondary mb-3">
                <Col sm={2}>
                    {t('document.navbar.main.editor.left.blocks.modals.blocksTagModal.btn.columnTitles.variant')}
                </Col>
                <Col className="pr-3">
                    <div className="d-flex align-items-baseline">
                        {t('document.navbar.main.editor.left.blocks.modals.blocksTagModal.btn.columnTitles.text')}
                        <QuestionCircle
                            data-uk-tooltip={t('documents:document.navbar.tags.editModal.modal.textTooltip')}
                            size={14}
                            className="text-secondary ml-1"
                        />
                    </div>
                </Col>
                <Col className="pr-3">
                    {t('document.navbar.main.editor.left.blocks.modals.blocksTagModal.btn.columnTitles.link')}
                </Col>
                <Col className="pr-3">
                    {t('documents:document.navbar.tags.editModal.modal.title')}
                    <QuestionCircle
                        data-uk-tooltip={t('documents:document.navbar.tags.editModal.modal.titleTooltip')}
                        size={14}
                        className="text-secondary ml-1"
                    />
                </Col>
                <Col>
                    {t('document.navbar.main.editor.left.blocks.modals.blocksTagModal.btn.columnTitles.reference')}
                </Col>
            </Row>

            {filteredDocumentVariants.map((variant, index) => (
                <TagRow
                    item={tagItems.find((_item) => _item.documentVariantId === variant.id)}
                    variant={variant}
                    isOverride={isOverride}
                    handleFormChange={handleFormChange}
                    defaultItem={defaultItem}
                    documentId={documentId}
                    toggleVariantItem={toggleVariantItem}
                    copyItem={copyItem}
                    baseVariantId={baseVariant.id}
                    key={`tag-${index}-variant-${variant.id}`}
                />
            ))}
        </Container>
    );
}
