import { Form as FForm, Formik, FormikHelpers, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import {
    Product,
    ProductLink,
    ReimbursementCode,
    ReimbursementProduct,
    ReimbursementProductCode,
    ReimbursementProductField,
} from '../../../../models/reimbursement.models';
import { FieldSelect, InputCheck } from '../../../publications_v2/helpers/FieldHelper';
import {
    reimbursementApi,
    useGetReimbursementCodesQuery,
    useGetReimbursementProductCodesQuery,
    useGetReimbursementProductFieldsQuery,
    useGetReimbursementProductsQuery,
} from '../../../../features/reimbursements/reimbursements';
import { useParams } from 'react-router-dom';
import ProductHelper from '../../../global/ProductHelper';
import HelperFunctions from '../../../global/HelperFunctions';
import { idToUri } from '../../../global/UriHelper';
import React, { MouseEvent, useEffect } from 'react';
import * as Yup from 'yup';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { RenderedContent } from '../reimbursement_product/FieldHistory';
import Spinner from '../../../global/Spinner';
import { useTranslation } from 'react-i18next';

interface FormInterface {
    productId?: string;
    selectedProductId?: string;
    codes: string[];
    fields: string[];
}

export function LoadProductRowsModal({
    currentProduct,
    handleClose,
}: {
    currentProduct: Product;
    handleClose: () => void;
}) {
    const params: { categoryId: string; productId: string; reimbursementId: string } = useParams();
    const { values, setFieldValue } = useFormikContext<ReimbursementProduct>();
    const { reimbursementProductCodes, reimbursementProductFields } = values;
    const { t } = useTranslation('reimbursements');

    const [getReimbursementProducts] = reimbursementApi.useLazyGetReimbursementProductsQuery();
    const [getReimbursementProductCodes] = reimbursementApi.useLazyGetReimbursementProductCodesQuery();
    const [getReimbursementProductFields] = reimbursementApi.useLazyGetReimbursementProductFieldsQuery();

    const getDescriptionFields = async (
        reimbursementProduct: ReimbursementProduct,
        fields: string[],
    ): Promise<ReimbursementProductField[]> => {
        const result = await getReimbursementProductFields(reimbursementProduct['@id'], true);
        const selectedDescriptionFields: ReimbursementProductField[] = result?.data
            ? result?.data.filter((_item: ReimbursementProductField) => fields.includes(_item['@id']))
            : ([] as ReimbursementProductField[]);

        const newFields = [] as ReimbursementProductField[];

        // Loop through existing codes and update new values
        reimbursementProductFields.forEach((_currentField) => {
            // Try to find new value
            const newField = selectedDescriptionFields.find(
                (_selectedField) => _selectedField.slug === _currentField.slug,
            );

            if (newField) {
                // New value found, update it
                newFields.push({
                    ..._currentField,
                    text: newField.text,
                });
            } else {
                // Not changed, use current value
                newFields.push(_currentField);
            }
        });

        // Loop through selected codes and check if it needs to be inserted
        selectedDescriptionFields.forEach((_selectedField) => {
            // Try to find new value
            const newCode = newFields.find((_code) => _selectedField.slug === _code.slug);

            if (newCode === undefined) {
                newFields.push({
                    slug: _selectedField.slug,
                    text: _selectedField.text,
                    forceEmpty: false,
                } as ReimbursementProductField);
            }
        });

        return newFields;
    };

    const getProductCodes = async (
        reimbursementProduct: ReimbursementProduct,
        codes: string[],
    ): Promise<ReimbursementProductCode[]> => {
        const result = await getReimbursementProductCodes(reimbursementProduct['@id'], true);
        const selectedProductCodes: ReimbursementProductCode[] = result?.data
            ? result.data.filter((_item: ReimbursementProductCode) => codes.includes(_item['@id']))
            : ([] as ReimbursementProductCode[]);

        const newCodes = [] as ReimbursementProductCode[];

        // Loop through existing codes and update new values
        reimbursementProductCodes.forEach((_currentCode) => {
            // Try to find new value
            const newCode = selectedProductCodes.find(
                (_selectedCode) => _selectedCode.reimbursementCode === _currentCode.reimbursementCode,
            );

            if (newCode) {
                // New value found, update it
                newCodes.push({
                    ..._currentCode,
                    text: newCode.text,
                });
            } else {
                // Not changed, use current value
                newCodes.push(_currentCode);
            }
        });

        // Loop through selected codes and check if it needs to be inserted
        selectedProductCodes.forEach((_selectedCode) => {
            // Try to find new value
            const newCode = newCodes.find((_code) => _selectedCode.reimbursementCode === _code.reimbursementCode);

            if (newCode === undefined) {
                newCodes.push({
                    reimbursementCode: _selectedCode.reimbursementCode,
                    text: _selectedCode.text,
                    category: _selectedCode.category,
                } as ReimbursementProductCode);
            }
        });

        return newCodes;
    };

    const loadData = async (reimbursementProduct: ReimbursementProduct, formData: FormInterface) => {
        const { codes, fields } = formData;

        // Product codes
        if (codes.length > 0) {
            const productCodes = await getProductCodes(reimbursementProduct, codes);

            if (productCodes.length > 0) {
                setFieldValue('reimbursementProductCodes', productCodes);
            }
        }

        // Description fields
        if (fields.length > 0) {
            const descriptionFields = await getDescriptionFields(reimbursementProduct, fields);

            if (descriptionFields.length > 0) {
                setFieldValue('reimbursementProductFields', descriptionFields);
            }
        }
    };

    const handleSubmit = (values: FormInterface, helpers: FormikHelpers<FormInterface>) => {
        getReimbursementProducts(idToUri(parseInt(params.reimbursementId), 'Reimbursement'), true).then(
            async (result) => {
                const reimbursementProduct: ReimbursementProduct | undefined = result.data
                    ? result.data.find((_item: ReimbursementProduct) => {
                          return (
                              _item.product === values.productId && _item.selectedProduct === values.selectedProductId
                          );
                      })
                    : undefined;

                if (reimbursementProduct) {
                    await loadData(reimbursementProduct, values);
                    handleClose();
                } else {
                    helpers.setSubmitting(false);
                }
            },
        );
    };

    return (
        <FormModal
            show={true}
            onHide={handleClose}
            title={t('view.loadProductRows.loadingContent')}
            size="lg"
            scrollable={true}
        >
            <Formik
                enableReinitialize={true}
                initialValues={
                    {
                        productId: undefined,
                        selectedProductId: undefined,
                        codes: [],
                        fields: [],
                    } as FormInterface
                }
                validationSchema={LoadProductRowsSchema}
                onSubmit={handleSubmit}
            >
                <FormBody
                    handleClose={handleClose}
                    currentProductIsLinkedProduct={ProductHelper.isLinkedProduct(currentProduct)}
                />
            </Formik>
        </FormModal>
    );
}

function FormBody({
    handleClose,
    currentProductIsLinkedProduct,
}: {
    handleClose: () => void;
    currentProductIsLinkedProduct: boolean;
}) {
    const params: { categoryId: string; reimbursementId: string } = useParams();
    const { values, isSubmitting, dirty, isValid, setFieldValue } = useFormikContext<FormInterface>();
    const { t } = useTranslation('reimbursements');

    const { product, isLinkedProduct }: { product: Product | undefined; isLinkedProduct: boolean } =
        reimbursementApi.endpoints.getProducts.useQueryState(parseInt(params.categoryId), {
            selectFromResult: ({ data }: { data?: Product[] | undefined }) => {
                const product = data?.find((_product) => _product['@id'] === values.productId);

                return {
                    product,
                    isLinkedProduct: product ? ProductHelper.isLinkedProduct(product) : false,
                };
            },
        });

    useEffect(() => {
        if (values.productId && values.selectedProductId === undefined && !isLinkedProduct) {
            setFieldValue('selectedProductId', values.productId);
        }
    }, [values.productId]);

    useEffect(() => {
        if (values.productId && values.selectedProductId) {
            if (isLinkedProduct) {
                setFieldValue('selectedProductId', undefined);
            } else {
                setFieldValue('selectedProductId', values.productId);
            }
        }
    }, [values.productId]);

    return (
        <>
            <FForm autoComplete="off" className="modal-body" id="load-product-rows-form">
                <>
                    <div className="mb-3">
                        <p>{t('view.loadProductRows.selectPolicyType')}</p>
                    </div>

                    <PrimaryProduct currentProductIsLinkedProduct={currentProductIsLinkedProduct} />

                    {product && isLinkedProduct && <SelectedProduct product={product} />}

                    {values.productId && values.selectedProductId && (
                        <SelectedReimbursementProduct
                            reimbursementUri={idToUri(parseInt(params.reimbursementId), 'Reimbursement')}
                            productId={values.productId}
                            selectedProductId={values.selectedProductId}
                        />
                    )}
                </>
            </FForm>

            <ModalFooter
                btnSubmitText={t('view.loadProductRows.loadingContent') || ''}
                btnCancelText={t('btn.close') || ''}
                dirty={dirty}
                isSubmitting={isSubmitting}
                isValid={isValid}
                onHide={handleClose}
                form="load-product-rows-form"
            />
        </>
    );
}

function PrimaryProduct({ currentProductIsLinkedProduct }: { currentProductIsLinkedProduct: boolean }) {
    const params: { categoryId: string; productId: string } = useParams();
    const { t } = useTranslation('reimbursements');

    const productId = parseInt(params.productId);
    const categoryUri = idToUri(parseInt(params.categoryId), 'Category');

    const { products } = reimbursementApi.endpoints.getProducts.useQueryState(parseInt(params.categoryId), {
        selectFromResult: ({ data }: { data?: Product[] | undefined }) => {
            if (data === undefined) {
                return {
                    products: [],
                };
            }

            const rootProducts = data
                .filter((_item) => _item.category === categoryUri && _item.enabled && _item.deletedAt === null)
                .filter((_item) => _item.parent === null)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            return {
                products: rootProducts.map((_product) => ({
                    ..._product,
                    options: _product.children
                        .filter((_child) => _child.enabled && _child.deletedAt === null)
                        .map((_child) => ({
                            ..._child,
                            label: `(${_child.code}) ${_child.name}`,
                            value: _child['@id'],
                            isDisabled: _child.id === productId && !currentProductIsLinkedProduct,
                        })),
                })),
            };
        },
    });

    console.log(products);

    return (
        <FieldSelect
            label={`${t('view.loadProductRows.policyType')}*`}
            name="productId"
            options={HelperFunctions.prepareDropdownData(products, 'name', '@id')}
            props={{
                placeholder: `${t('view.loadProductRows.titleSelectType')}...`,
            }}
            labelWidth={5}
            formWidth={7}
        />
    );
}

function SelectedProduct({ product }: { product: Product }) {
    const params: { categoryId: string; productId: string; selectedProductId: string } = useParams();
    const { t } = useTranslation('reimbursements');

    const isSameBaseProduct = product.id === parseInt(params.productId);
    const isLinkedProduct = ProductHelper.isLinkedProduct(product);
    const selectedProductUris = isLinkedProduct
        ? product.extendedProducts.map((productLink: ProductLink) => productLink.baseProduct)
        : [product['@id']];

    const { products }: { products: Product[] } = reimbursementApi.endpoints.getProducts.useQueryState(
        parseInt(params.categoryId),
        {
            selectFromResult: ({ data }: { data?: Product[] | undefined }) => ({
                products: data
                    ? data
                          .filter((_product) => selectedProductUris.includes(_product['@id']) && _product.enabled)
                          .map((_product) => ({
                              ..._product,
                              label: `(${_product.code}) ${_product.name}`,
                              value: _product['@id'],
                              isDisabled: isSameBaseProduct && _product.id === parseInt(params.selectedProductId),
                          }))
                    : [],
            }),
        },
    );

    return (
        <FieldSelect
            label={`${t('view.loadProductRows.reimbursement')}*`}
            name="selectedProductId"
            options={products}
            props={{
                placeholder: `${t('view.loadProductRows.titleSelectReimbursement')}...`,
            }}
            labelWidth={5}
            formWidth={7}
        />
    );
}

function SelectedReimbursementProduct({
    reimbursementUri,
    productId,
    selectedProductId,
}: {
    reimbursementUri: string;
    productId: string;
    selectedProductId: string;
}) {
    const { setFieldValue } = useFormikContext<FormInterface>();
    const { t } = useTranslation('reimbursements');

    const { reimbursementProduct }: { reimbursementProduct: ReimbursementProduct | undefined } =
        useGetReimbursementProductsQuery(reimbursementUri, {
            selectFromResult: ({ data }: { data?: ReimbursementProduct[] | undefined }) => ({
                reimbursementProduct: data
                    ? data.find((_item: ReimbursementProduct) => {
                          return _item.product === productId && _item.selectedProduct === selectedProductId;
                      })
                    : undefined,
            }),
        });

    const selectAll = (event: MouseEvent, field: string, values: string[]) => {
        event.preventDefault();
        setFieldValue(field, values);
    };

    const selectNone = (event: MouseEvent, field: string) => {
        event.preventDefault();
        setFieldValue(field, []);
    };

    if (reimbursementProduct === undefined) {
        return <div className="text-danger mt-3">{t('view.loadProductRows.noContent')}</div>;
    }

    return (
        <div className="reimbursement-codes">
            <ReimbursementProductCodesRows
                reimbursementProduct={reimbursementProduct}
                selectAll={selectAll}
                selectNone={selectNone}
            />

            <ReimbursementProductFieldsRows
                reimbursementProduct={reimbursementProduct}
                selectAll={selectAll}
                selectNone={selectNone}
            />
        </div>
    );
}

function ReimbursementProductCodesRows({
    reimbursementProduct,
    selectAll,
    selectNone,
}: {
    reimbursementProduct: ReimbursementProduct;
    selectAll: (event: MouseEvent, field: string, values: string[]) => void;
    selectNone: (event: MouseEvent, field: string) => void;
}) {
    const {
        reimbursementProductCodes,
        isLoading,
        isUninitialized,
    }: { reimbursementProductCodes: ReimbursementProductCode[]; isLoading: boolean; isUninitialized: boolean } =
        useGetReimbursementProductCodesQuery(reimbursementProduct['@id'], {
            selectFromResult: ({ data, isLoading, isUninitialized }) => ({
                reimbursementProductCodes: data
                    ? data.filter((code: ReimbursementProductCode) => code.text !== '')
                    : [],
                isLoading,
                isUninitialized,
            }),
        });
    const { t } = useTranslation('reimbursements');

    if (isLoading || isUninitialized) {
        return <Spinner />;
    }

    return (
        <>
            <div className="d-flex align-items-center py-3">
                <div className="text-primary mr-2" style={{ fontSize: '1.2rem' }}>
                    {t('view.loadProductRows.reimbursementCodes')}
                </div>

                <SelectAllNone
                    selectAll={(event: MouseEvent) =>
                        selectAll(
                            event,
                            'codes',
                            reimbursementProductCodes.map((code) => code['@id']),
                        )
                    }
                    selectNone={(event: MouseEvent) => selectNone(event, 'codes')}
                />
            </div>

            {reimbursementProductCodes.length === 0 && (
                <div className="text-secondary">{t('view.loadProductRows.noCodes')}</div>
            )}

            {reimbursementProductCodes.map((code, index) => (
                <ReimbursementProductCodeRow code={code} index={index} key={`row-${code.id}`} />
            ))}
        </>
    );
}

function ReimbursementProductFieldsRows({
    reimbursementProduct,
    selectAll,
    selectNone,
}: {
    reimbursementProduct: ReimbursementProduct;
    selectAll: (event: MouseEvent, field: string, values: string[]) => void;
    selectNone: (event: MouseEvent, field: string) => void;
}) {
    const {
        reimbursementProductFields,
        isLoading,
        isUninitialized,
    }: { reimbursementProductFields: ReimbursementProductField[]; isLoading: boolean; isUninitialized: boolean } =
        useGetReimbursementProductFieldsQuery(reimbursementProduct['@id'], {
            selectFromResult: ({ data, isLoading, isUninitialized }) => ({
                reimbursementProductFields: data
                    ? data.filter((field: ReimbursementProductField) => field.text !== '')
                    : [],
                isLoading,
                isUninitialized,
            }),
        });
    const { t } = useTranslation('reimbursements');

    if (isLoading || isUninitialized) {
        return <Spinner />;
    }

    return (
        <>
            <div className="d-flex align-items-center pt-4 pb-3">
                <div className="text-primary mr-2" style={{ fontSize: '1.2rem' }}>
                    {t('view.loadProductRows.description')}
                </div>

                <SelectAllNone
                    selectAll={(event: MouseEvent) =>
                        selectAll(
                            event,
                            'fields',
                            reimbursementProductFields.map((field) => field['@id']),
                        )
                    }
                    selectNone={(event: MouseEvent) => selectNone(event, 'fields')}
                />
            </div>

            {reimbursementProductFields.length === 0 && (
                <div className="text-secondary">{t('view.loadProductRows.noDescriptions')}</div>
            )}

            {reimbursementProductFields.map((field, index) => (
                <ReimbursementProductFieldRow field={field} index={index} key={`field-${field.id}`} />
            ))}
        </>
    );
}

function ReimbursementProductCodeRow({ code, index }: { code: ReimbursementProductCode; index: number }) {
    const params: { categoryId: string } = useParams();
    const { values } = useFormikContext<FormInterface>();

    const { reimbursementCode }: { reimbursementCode: ReimbursementCode | undefined } = useGetReimbursementCodesQuery(
        idToUri(parseInt(params.categoryId), 'Category'),
        {
            selectFromResult: ({ data }) => ({
                reimbursementCode: data
                    ? data.find((_code: ReimbursementCode) => _code['@id'] === code.reimbursementCode)
                    : undefined,
            }),
        },
    );

    if (reimbursementCode === undefined) {
        return null;
    }

    const isSelected = values.codes.includes(code['@id']);

    return (
        <div className="py-4 px-3" style={{ backgroundColor: index % 2 ? undefined : '#f7fbff' }}>
            <Row>
                <Col xs={1} className="d-flex align-items-center">
                    <InputCheck
                        name="codes"
                        props={{
                            value: code['@id'],
                            id: `code-${code.id}`,
                        }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                    <div className="font-weight-bold" style={{ color: '#344054', fontSize: 12 }}>
                        [{reimbursementCode.code}]
                    </div>
                </Col>
                <Col>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                {reimbursementCode.type === 1 && <>&euro;</>}
                                {reimbursementCode.type === 2 && <>%</>}
                                {reimbursementCode.type === 3 && <>#</>}
                            </span>
                        </div>
                        <input
                            className="form-control"
                            value={code.text}
                            readOnly
                            style={{
                                color: isSelected ? '#212121' : undefined,
                                backgroundColor: isSelected ? '#fff' : undefined,
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

function ReimbursementProductFieldRow({ field, index }: { field: ReimbursementProductField; index: number }) {
    const params: { categoryId: string } = useParams();
    const { category } = useGetCategory(parseInt(params.categoryId));
    const { values } = useFormikContext<FormInterface>();

    const productField = category
        ? category.reimbursementProductFields.find((_field) => _field.slug === field.slug)
        : undefined;

    if (productField === undefined) {
        return null;
    }

    const isSelected = values.fields.includes(field['@id']);

    return (
        <div className="py-4 px-3" style={{ backgroundColor: index % 2 ? undefined : '#f7fbff' }}>
            <Row>
                <Col xs={1} className="d-flex align-items-center">
                    <InputCheck
                        name="fields"
                        props={{
                            value: field['@id'],
                            id: `field-${field.id}`,
                        }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                    <div className="font-weight-bold" style={{ color: '#344054', fontSize: 13 }}>
                        {productField.name}
                    </div>
                </Col>
                <Col>
                    <RenderedContent content={field.text} isActive={isSelected} />
                </Col>
            </Row>
        </div>
    );
}

function SelectAllNone({
    selectAll,
    selectNone,
}: {
    selectAll: (event: MouseEvent) => void;
    selectNone: (event: MouseEvent) => void;
}) {
    const { t } = useTranslation('reimbursements');

    return (
        <div className="d-flex small">
            <a href="#" onClick={selectAll}>
                {t('view.loadProductRows.all')}
            </a>
            <div style={{ margin: '0 2px' }}>/</div>
            <a href="#" onClick={selectNone}>
                {t('view.loadProductRows.none')}
            </a>
        </div>
    );
}

const LoadProductRowsSchema = Yup.object().shape({
    productId: Yup.string().required(),
    selectedProductId: Yup.string().required(),
    codes: Yup.array().when('fields', {
        is: (fields: string[]) => fields.length === 0,
        then: Yup.array().min(1),
    }),
});
