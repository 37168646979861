import { Dropdown, Nav } from 'react-bootstrap';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { CaretRightFill, CollectionFill, PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useGetCategoryGroups } from 'pages/reimbursements/hooks/useGetCategoryGroups';
import { IconButton } from 'components/Buttons';
import { useEffect, useState } from 'react';
import { EditFolderModal } from 'pages/reimbursements/views/modals/EditFolderModal';
import cx from 'classnames';
import Constants from 'config/Constants';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import HelperFunctions from 'pages/global/HelperFunctions';
import DropdownMenu from 'pages/global/DropdownMenu';
import { useDeleteCategoryGroupMutation } from 'features/reimbursements/reimbursements';
import { INDEX_PATH } from 'scenes/Reimbursements';
import _ from 'lodash';
import { RxDragHandleDots2 } from 'react-icons/rx';

export function Sidebar({ handleMove }) {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { folderId } = useParams();
    const { t } = useTranslation('global');
    const rootLevelGroups = useGetCategoryGroups();
    const [editFolderModal, setEditFolderModal] = useState(false);

    return (
        <>
            <div className="pt-4">
                <Nav className="folder-nav flex-column mb-3">
                    <Nav.Link
                        active={folderId === undefined}
                        className="nav-dropdown-hover d-flex align-items-center"
                        onClick={() => {
                            history.push(generatePath(path));
                        }}
                    >
                        <div className="font-weight-bolder">
                            <CollectionFill size={14} className="mr-2" />
                            {t('contentSidebar.all')}
                        </div>

                        <RootActions setShowModal={setEditFolderModal} rootLevelGroups={rootLevelGroups} />
                    </Nav.Link>
                </Nav>

                <RootGroups groups={rootLevelGroups} setShowModal={setEditFolderModal} handleMove={handleMove} />
            </div>

            <EditFolderModal payload={editFolderModal} close={() => setEditFolderModal(false)} />
        </>
    );
}

function RootGroups({ groups = [], setShowModal, handleMove }) {
    return (
        <>
            {groups.map((group, index) => (
                <RootGroup
                    group={group}
                    setShowModal={setShowModal}
                    handleMove={handleMove}
                    index={index}
                    isLast={groups.length - 1 === index}
                    key={`sidebar-group-${group.id}`}
                />
            ))}
        </>
    );
}

function RootGroup({ group, setShowModal, draggableType, handleMove, index, isLast }) {
    const childGroups = useGetCategoryGroups(group['@id']);
    const [showContents, setShowContents] = useState(false);
    const { folderId } = useParams();

    useEffect(() => {
        if (folderId && !showContents) {
            const groupId = parseInt(folderId);
            const hasChild = childGroups.some((childGroup) => childGroup.id === groupId);

            if (hasChild) {
                setShowContents(true);
            }
        }
    }, [folderId, showContents, childGroups]);

    return (
        <>
            <div
                className="nav-dropdown-hover d-flex align-items-center mb-1 cursor-pointer"
                style={{
                    padding: '0.6rem 1.15rem 0.4rem 1.5rem',
                }}
                onClick={toggleContents}
            >
                <div className="font-weight-bolder d-flex justify-content-between">
                    <div className="sidebar-titles">{group.name}</div>
                    <CaretRightFill
                        className={cx('sidebar-icons ml-2', {
                            'rotate-90': showContents,
                        })}
                    />
                </div>

                <div className="ml-auto">
                    <GroupActions setShowModal={setShowModal} folder={group} groups={childGroups} />
                </div>
            </div>
            {showContents && (
                <Droppable droppableId={`group-${group.id}`} type="group">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <Nav
                                className={cx('folder-nav droppable flex-column', {
                                    'dragging-over': snapshot.isDraggingOver,
                                })}
                            >
                                {childGroups.map((childGroup, childIndex) => (
                                    <Group
                                        group={childGroup}
                                        setShowModal={setShowModal}
                                        index={childIndex}
                                        handleMove={handleMove}
                                        isLast={childGroups.length - 1 === childIndex}
                                        key={`sidebar-group-${childGroup.id}`}
                                    />
                                ))}

                                {provided.placeholder}
                            </Nav>
                        </div>
                    )}
                </Droppable>
            )}
        </>
    );

    function toggleContents() {
        setShowContents((prevState) => !prevState);
    }
}

function Group({ group, setShowModal, index, handleMove, isLast }) {
    const { folderId } = useParams();
    const history = useHistory();
    const isActive = folderId === _.toString(group.id);

    return (
        <Draggable draggableId={`group-${group.id}`} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(provided.draggableProps.style)}
                >
                    <Nav.Link
                        as="div"
                        className={cx('d-flex align-items-center position-relative nav-dropdown-hover', {
                            'is-dragging': snapshot.isDragging,
                        })}
                        active={isActive}
                        onClick={() => {
                            if (isActive === false) {
                                history.push(
                                    generatePath(INDEX_PATH, {
                                        folder: Constants.folders.folder,
                                        folderId: group.id,
                                    }),
                                );
                            }
                        }}
                    >
                        <RxDragHandleDots2 size={16} className="flex-shrink-0 text-color icon-grip" />

                        <div className="small">{group.name}</div>

                        <div className="d-flex align-items-center ml-auto">
                            <GroupActions
                                setShowModal={setShowModal}
                                folder={group}
                            />

                            <div className="text-center small ml-2" style={{ minWidth: 16 }}>
                                {group.categories.length}
                            </div>
                        </div>
                    </Nav.Link>
                </div>
            )}
        </Draggable>
    );
}

function GroupActions({ setShowModal, folder, groups = [] }) {
    const history = useHistory();
    const [dropdownIsOpen, setDropDownOpen] = useState(false);
    const { t } = useTranslation('global');

    const [deleteCategoryGroup] = useDeleteCategoryGroupMutation();

    return (
        <Dropdown
            className={cx('d-flex align-items-center cursor-pointer mr-1 font-weight-bolder', {
                'dropdown-open': dropdownIsOpen,
            })}
            onSelect={handleSelect}
            onToggle={(isOpen, event) => {
                event.stopPropagation();
                setDropDownOpen(isOpen);
            }}
        >
            <Dropdown.Toggle
                bsPrefix="docrev-dropdown-toggle"
                as={ThreeDotsVertical}
                size={16}
                id={`folder-${folder.id}-dropdown`}
                className="sidebar-icons"
            />

            <DropdownMenu>
                {folder.parent === null && (
                    <Dropdown.Item eventKey="insert">{t('contentSidebar.map.menu.subfolder')}&hellip;</Dropdown.Item>
                )}
                <Dropdown.Item eventKey="edit">{t('contentSidebar.map.menu.changeName')}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="delete" disabled={!canDelete()}>
                    <span className={canDelete() ? 'text-danger' : 'text-muted'}>
                        {t('contentSidebar.map.menu.delete')}&hellip;
                    </span>
                </Dropdown.Item>
            </DropdownMenu>
        </Dropdown>
    );

    function handleSelect(eventKey) {
        switch (eventKey) {
            case 'insert':
                setShowModal({
                    parent: folder['@id'],
                    sortOrder: groups.length > 0 ? groups[groups.length - 1].sortOrder + 1 : 0,
                });
                break;
            case 'edit':
                setShowModal(folder);
                break;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('contentSidebar.map.confirmDelete', { name: folder.name })}`,
                    'danger',
                    false,
                )
                    .then(() => {
                        deleteCategoryGroup(folder['@id']).then(() => {
                            history.push(generatePath(INDEX_PATH));
                        });
                    })
                    .catch(() => {});
                break;
        }
    }

    function canDelete() {
        return folder.categories.length === 0 && groups.length === 0;
    }
}

function RootActions({ setShowModal, rootLevelGroups = [] }) {
    const { t } = useTranslation('global');

    const getNewSortOrder = () => {
        if (rootLevelGroups.length === 0) {
            return 0;
        }

        return rootLevelGroups[rootLevelGroups.length - 1].sortOrder + 1;
    };

    return (
        <IconButton
            className="ml-auto"
            onClick={() => setShowModal({ sortOrder: getNewSortOrder() })}
            tooltip={`${t('contentSidebar.map.chaptersMap')}...`}
            icon={<PlusCircle size={14} />}
        />
    );
}

const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
});
