import UIkit from 'uikit';
import Language from '../../language/Language';
import Constants from '../../config/Constants';
import { isString } from 'uikit/src/js/util';
import EditorHelper from './EditorHelper';
import React from 'react';
import slugify from 'slugify';
import _ from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import striptags from 'striptags';
import { mapValuesDeep } from 'deepdash-es/standalone';
import { LabelWithPrefix } from 'components/Select';
import { stripHtml } from 'string-strip-html';
import queryString from 'query-string';
import { DateTime } from 'luxon';

const emptyArray = [];

const HelperFunctions = {
    addToAllVariants(allVariants, variant) {
        allVariants.push({
            id: variant.id,
            name: variant.name,
            prefix: variant.prefix,
            parentId: variant.parentId,
            sortOrder: variant.sortOrder,
            variantGroup: variant.variantGroup,
            enabled: variant.enabled,
        });

        if (variant.children.length > 0) {
            variant.children.map((variant) => {
                return this.addToAllVariants(allVariants, variant);
            });
        }

        return allVariants;
    },
    arrayMove(array, fromIndex, toIndex) {
        return arrayMoveImmutable(array, fromIndex, toIndex);
    },
    arrayInsert(array, item, index) {
        return [...array.slice(0, index), item, ...array.slice(index)];
    },
    booleanToString(val) {
        if (isString(val)) {
            val = val === '1';
        }

        return val === false ? Language.getTranslation('no', 'buttons') : Language.getTranslation('yes', 'buttons');
    },
    changesSaved() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 2000);
        });
    },
    checkRoleOrPermission(role, requiredRoles) {
        // required role can have the form of an array including roles that have permission
        // and also of an object containing a single key 'not' with an array including roles that do not have permission
        if (role === undefined || requiredRoles === undefined) return false;

        if (requiredRoles.hasOwnProperty('not')) {
            return requiredRoles.not.indexOf(role) < 0;
        }

        try {
            return requiredRoles.indexOf(role) >= 0;
        } catch (e) {
            return false;
        }
    },
    clone(obj) {
        if (null == obj || 'object' != typeof obj) return obj;
        let copy = obj.constructor();
        for (let attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    },
    csvToArray(text = '') {
        let p = '',
            row = [''],
            ret = [row],
            i = 0,
            r = 0,
            s = !0,
            l;
        for (l of text) {
            if ('"' === l) {
                if (s && l === p) row[i] += l;
                s = !s;
            } else if (',' === l && s) l = row[++i] = '';
            else if ('\n' === l && s) {
                if ('\r' === p) row[i] = row[i].slice(0, -1);
                row = ret[++r] = [(l = '')];
                i = 0;
            } else row[i] += l;
            p = l;
        }
        return ret;
    },
    alertModal(message) {
        return UIkit.modal.alert(striptags(message));
    },
    confirmModal(translation, type = 'default', useTranslation = true, okLabel = '', cancelLabel = '') {
        const msg = useTranslation ? Language.getTranslation(translation, 'messages') : striptags(translation);
        const ok = okLabel === '' ? Language.getTranslation('yes', 'buttons', 'global') : okLabel;
        const cancel = cancelLabel === '' ? 'Annuleer' : cancelLabel;

        return new Promise((resolve, reject) => {
            let message = msg;
            if (type && type === 'danger') {
                message = "<span class='text-danger font-weight-bold'>" + msg + '</span>';
            }
            UIkit.modal
                .confirm(message, {
                    i18n: {
                        ok,
                        cancel,
                    },
                    clsPage: `uk-modal-page uk-modal-${type}`,
                })
                .then(
                    () => resolve(),
                    () => reject(),
                );
        });
    },
    dynamicSort(property, sortOrder = 1) {
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    },
    emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    sortByString(property) {
        return (a, b) => a[property].localeCompare(b[property], undefined, { sensitivity: 'base' });
    },
    filterNumbers(value) {
        return value.match(/\d+/g).join([]);
    },
    findInDocument(ref, document) {
        if (!document) {
            return undefined;
        }

        if (ref.type === 'section') {
            return this.getByValue(document.sections, 'id', parseInt(ref.id));
        } else if (ref.type === 'area') {
            return this.findArea(parseInt(ref.id), document);
        } else if (ref.type === 'block') {
            const area = this.findArea(parseInt(ref.areaId), document);

            if (area) {
                return {
                    title: `Block #${ref.id} - ${area.title}`,
                };
            }
        }

        return undefined;
    },
    findArea(areaId, document) {
        for (const section of document.sections) {
            // Check if the section has an 'areas' property and it is an array
            if (section.areas && Array.isArray(section.areas)) {
                for (const area of section.areas) {
                    if (area.id === areaId) {
                        return area;
                    }
                }
            }
        }

        return undefined;
    },
    findTranslation(entity, locale, field) {
        if (locale === Constants.language.defaultLanguage) {
            return entity.hasOwnProperty(field) ? entity[field] : '';
        }

        if (entity.hasOwnProperty('translations') === false || entity.translations.length === 0) {
            return '';
        }

        const translation = this.findTranslationInArray(entity.translations, locale, field);

        return translation ? translation['content'] : '';
    },
    findTranslationInArray(arr, locale, field) {
        return arr.find((translation) => {
            return translation['locale'] === locale && translation['field'] === field;
        });
    },
    flatten(collection = [], flattened = [], childPropertyName = 'children') {
        _.each(collection, (obj) => {
            flattened.push(obj);
            this.flatten(obj[childPropertyName], flattened, childPropertyName);
        });

        return flattened;
    },
    formIsValid(formData, requiredFields) {
        if (requiredFields.length === 0) {
            return true;
        }

        let formIsValid = true;

        requiredFields.forEach((field) => {
            if (formData.hasOwnProperty(field) && formData[field].length < 1) {
                formIsValid = false;
            }
        });

        return formIsValid;
    },
    getByValue(arr, attr, value) {
        for (let i = 0, iLen = arr.length; i < iLen; i++) {
            if (arr[i][attr] === value) return arr[i];
        }
    },
    getByValueRecursive(arr, value, attr = 'id', childProp = 'children') {
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i][attr] === value) {
                    return arr[i];
                }
                const found = this.getByValueRecursive(arr[i][childProp], value, attr, childProp);
                if (found) return found;
            }
        }
    },
    getLocalDateTime(timestamp) {
        const dateObj = new Date(timestamp * 1000);
        return (
            String(dateObj.getDate()).padStart(2, '0') +
            '-' +
            String(dateObj.getMonth() + 1).padStart(2, '0') +
            '-' +
            dateObj.getFullYear() +
            ' ' +
            dateObj.getHours() +
            ':' +
            String(dateObj.getMinutes()).padStart(2, '0')
        );
    },
    getParameterFromUrl(param) {
        let search = '/' + param + '/';
        let posStart = window.location.pathname.indexOf(search) + search.length; // locate the parameter in the url
        let posEnd = window.location.pathname.indexOf('/', posStart);
        if (posEnd === -1) {
            return window.location.pathname.slice(posStart);
        } else {
            return window.location.pathname.slice(posStart, posEnd);
        }
    },
    getSectionFromUrl() {
        let section = '';
        if (window.location.pathname === '/') {
            section = 'dashboard';
        } else {
            let posEnd = window.location.pathname.indexOf('/', 2);
            if (posEnd === -1) {
                section = window.location.pathname.slice(1);
            } else {
                section = window.location.pathname.slice(1, posEnd);
            }
        }
        return section;
    },
    getTime(timestamp) {
        // expects timestamp in seconds
        const dateObj = new Date(timestamp * 1000);
        return dateObj.getHours() + ':' + String(dateObj.getMinutes()).padStart(2, '0');
    },
    getTimestampNow() {
        // timestamp will be in seconds, not miliseconds
        const date = new Date();
        return Math.floor(date.getTime() / 1000);
    },
    getUniversalDate(timestamp) {
        // timestamp must be in seconds, not miliseconds!
        if (timestamp === undefined) {
            // use 'now'
            timestamp = this.getTimestampNow();
        }
        let dateObj = new Date(timestamp * 1000);
        let dateString =
            dateObj.getFullYear() +
            '-' +
            ('0' + (dateObj.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + dateObj.getDate()).slice(-2);
        return dateString;
    },
    getUserIdFromUrl() {
        return this.getParameterFromUrl('id');
    },
    formatTimestampForInput(text) {
        return DateTime.fromISO(text).toISO().slice(0, 16);
    },
    handleKeyPress(event, key, callback) {
        if (event.key === key) {
            callback();
        }
    },
    isEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    },
    empty(value) {
        return value == null || value.length === 0;
    },
    isLastInArray(items = [], object = {}) {
        const lastItem = items.slice(-1)[0];
        return lastItem.id === object.id;
    },
    isInt(value) {
        let x;
        return isNaN(value) ? !1 : ((x = parseFloat(value)), (0 | x) === x);
    },
    moveItem(itemId, items, direction, sortProperty = 'sortOrder') {
        // Make sure the items are sorted
        const itemsSorted = [...items].sort(this.dynamicSort(sortProperty));
        const itemIndex = itemsSorted.findIndex((item) => item.id === itemId);

        let swapIndex = -1;
        if (direction === 'up' && itemIndex > 0) {
            swapIndex = itemIndex - 1;
        } else if (direction === 'down' && itemIndex < itemsSorted.length - 1) {
            swapIndex = itemIndex + 1;
        }

        if (swapIndex >= 0) {
            const item = {
                ...itemsSorted[itemIndex],
                [sortProperty]: itemsSorted[swapIndex][sortProperty],
            };
            const swapItem = {
                ...itemsSorted[swapIndex],
                [sortProperty]: itemsSorted[itemIndex][sortProperty],
            };

            return { items, updated: [item, swapItem] };
        } else {
            return false;
        }
    },
    prepareDropdownData(items, labelKey = 'name', valueKey = 'id') {
        if (items !== undefined && items.length > 0) {
            return items.map((item) => {
                return {
                    ...item,
                    label: item[labelKey],
                    value: item[valueKey],
                };
            });
        }
        return [];
    },
    prepareOptionValues(options = [], nameProp = 'name', prefixProp = 'prefix') {
        return mapValuesDeep(options, (value, key, parentValue) => {
            if (key === 'label') {
                return <LabelWithPrefix name={_.get(parentValue, nameProp)} prefix={_.get(parentValue, prefixProp)} />;
            }

            return value;
        });
    },
    prepareQuery(url, params = {}) {
        if (_.isEmpty(params)) {
            return url;
        }

        return queryString.stringifyUrl({ url, query: params }, { arrayFormat: 'bracket' });
    },
    prepareRefs(text, document) {
        return text.replace(/\[Ref (.*?)]/gm, (match) => {
            const ref = {
                id: 0,
                document: 0,
                type: '',
            };

            // Replace html entity
            const rawTag = match;
            let tag = rawTag.replace(/&#61;/g, '=');

            // Remove start and end part of [Tag ... ]
            tag = tag.slice(5).slice(0, -1);

            // Loop string for params
            tag.split(' ').forEach((params) => {
                const param = params.split('=');
                const key = param[0];

                ref[key] = param[1];
            });

            // Check if id is set
            if (_.isEmpty(ref.id)) {
                // Parse failed
                return rawTag;
            }

            // Find entity in Document
            const object = this.findInDocument(ref, document);

            let title = '';

            if (object === undefined) {
                title = `${Language.getTranslation('refNotFound', 'text', 'editor')}`;
            } else {
                title = `${object.title}`;
            }

            return EditorHelper.createTagElement(rawTag, title);
        });
    },

    prepareTags(text, document = undefined, variant, useHtmlTag, allowQuickEdit = false) {
        if (text === undefined || text === null) {
            return false;
        }

        if (document === undefined) {
            return text;
        }

        text = this.prepareTooltipTags(text);
        text = this.prepareTagChangedValues(text);
        text = this.prepareRefs(text, document);
        const tags = document.tags;

        if (!tags) {
            return text;
        }

        // A tag can start with "[Tag" or only "["
        const regex = /\[(Tag |)(.*?)\]/gm;
        const variantId = typeof variant === 'object' ? variant.id : variant;

        text = text.replace(regex, (match) => {
            const shortTag = !match.startsWith('[Tag ');
            const tagName = match.slice(shortTag ? 1 : 5, match.length - 1);
            const tag = this.getByValue(tags, 'name', tagName);

            if (tag === undefined) {
                return match;
            }

            let tagText = '[' + tagName + ']';
            let tagClass = 'fixed-link';

            if (variant !== 'base') {
                if (tag.deletedAt === null) {
                    if (variantId !== undefined) {
                        let tagItem = undefined;

                        if (tag.documentId === null) {
                            // Global Tag
                            tagItem = {
                                text: tag.tagValue?.text ?? '',
                            };
                        } else {
                            // Check if the variant of the Tag can be found
                            tagItem = tag.tagItems
                                ? this.getByValue(tag.tagItems, 'documentVariantId', variantId)
                                : undefined;
                        }

                        if (tagItem !== undefined) {
                            if (tagItem.text !== null) {
                                if (tagItem.text !== '') {
                                    tagText = tagItem.text;
                                } else {
                                    tagText = '[' + Language.getTranslation('tagIsEmpty', 'messages') + ']';
                                }
                            } else {
                                tagText = '[' + Language.getTranslation('tagIsEmpty', 'messages') + ']';
                            }
                        } else {
                            tagClass += ' deleted';
                        }
                    } else {
                        tagText = '[' + Language.getTranslation('tagIsEmpty', 'messages') + ']';
                    }
                } else {
                    tagText = '[' + Language.getTranslation('tagIsDeleted', 'messages') + ']';
                    tagClass += ' deleted';
                }
            }

            if (allowQuickEdit) {
                return `<button type="button" class="tags-button" data-tag="${tagName}">test</button>`;
            }

            return shortTag && (useHtmlTag === undefined || !useHtmlTag)
                ? tagText
                : '<span class="' + tagClass + '" data-tag="' + tagName + '">' + tagText + '</span>';
        });

        return text;
    },

    /**
     * Formats the ugly [Tooltip text= value=] into a little icon that shows the tooltip when hovered over.
     * @param text
     * @returns {boolean|*}
     */
    prepareTooltipTags(text) {
        if (text === undefined || text === null) {
            return false;
        }

        // A tag tooltip definition starts with "[Tooltip"
        const regex = /\[Tooltip(.*?)\]/gm;

        text = text.replace(regex, (match) => {
            const parts = match.split('value=');

            if (parts.length === 2) {
                const tooltipValue = stripHtml(this.phpUrldecode(parts[1].slice(0, -1)).trim());
                const tooltip = tooltipValue.result.replaceAll("'", '"').replaceAll('‘', '"').replaceAll('’', '"');
                return `<sup data-uk-tooltip='title: ${tooltip}'>
                        <span data-uk-icon='icon: question; ratio: 0.75'></span>
                    </sup>`;
            }

            return match;
        });

        return text;
    },

    /**
     * Formats the string [~~ChangedValues= ~~] into a little icon that shows the changed values when hovering over.
     *
     * @param text
     * @returns {boolean|*}
     */
    prepareTagChangedValues(text) {
        if (text === undefined || text === null) {
            return false;
        }

        // If a tag has history included the format is "[~~ChangedValues=XYZ~~]"
        const regex = /\[~~ChangedValues=(.*?)~~\]/gms;

        text = text.replace(regex, (_, groupedValue) => {
            const tooltipContent =
                `<span>` +
                `<div class='tooltip-banner'><span class='tooltip-title'>Wijzigingen in deze revisieronde</span></div>` +
                `<div class='inner-tooltip'>${groupedValue}</div>` +
                `</span>`;

            // Auto-formatting might break this (convert " to ') so just find/replace them
            return `<sup data-uk-tooltip='title: $$tooltipContent$$; cls: uk-active mark-changes'>
                        <span class='tag-changes-icon' data-uk-icon='icon: info; ratio: 0.75'></span>
                    </sup>`
                .replace(/'/g, '"')
                .replace('$$tooltipContent$$', tooltipContent);
        });

        return text;
    },

    /**
     * Implementation to decode a string that was encoded by urlEncode in php.
     *
     * @param url
     * @returns {string}
     */
    phpUrldecode(url) {
        return decodeURIComponent(url.replace(/\+/g, ' '));
    },
    queryResult(result, hydra = true) {
        const { currentData, isUninitialized, isLoading, isFetching } = result;
        const items = currentData ? currentData[hydra ? 'hydra:member' : 'member'] : emptyArray;

        return {
            ...result,
            items,
            totalItems: currentData ? currentData[hydra ? 'hydra:totalItems' : 'totalItems'] : 0,
            isEmpty: items.length === 0 && !isUninitialized && !isLoading && !isFetching,
            isLoading: isUninitialized || (isFetching && items.length === 0),
        };
    },
    removeSpaces(string = '') {
        return string.replace(/[^a-z0-9]/gi, '');
    },
    slugify(string = '', allowForwardSlash = false) {
        if (allowForwardSlash) {
            return slugify(string, {
                lower: true,
                strict: false,
                trim: false,
                remove: /[^\w\s$*_+~.()'"!\-:@/]+/g,
            });
        }

        return slugify(string, {
            lower: true,
            strict: true,
            trim: false,
        });
    },
    tryParseJSON(jsonString, defaultValue = false) {
        try {
            const o = JSON.parse(jsonString);

            // Handle non-exception-throwing cases:
            // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
            // but... JSON.parse(null) returns null, and typeof null === "object",
            // so we must check for that, too. Thankfully, null is falsey, so this suffices:
            if (o && typeof o === 'object') {
                return o;
            }
        } catch (e) {}

        return defaultValue;
    },
    upsert(array, element, property = 'id') {
        const newArray = [...array];

        const i = newArray.findIndex((_element) => _.get(_element, property) === _.get(element, property));
        if (i > -1) newArray[i] = element;
        else newArray.push(element);

        return newArray;
    },
    variantCount(variant, count, enabledVariants) {
        if (variant.enabled && !variant.variantGroup) {
            if (enabledVariants !== undefined && this.getByValue(enabledVariants, 'id', variant.id) !== undefined) {
                count++;
            }
        }

        if (variant.children.length > 0) {
            for (let i = 0; i < variant.children.length; i++) {
                const childVariant = variant.children[i];

                if (childVariant.enabled) {
                    if (childVariant.children.length > 0) {
                        count += this.variantCount(childVariant, count, enabledVariants);
                    } else {
                        if (
                            enabledVariants !== undefined &&
                            this.getByValue(enabledVariants, 'id', childVariant.id) !== undefined
                        ) {
                            count++;
                        }
                    }
                }
            }
        }

        return count;
    },
    variantAsList(variant, list = [], depth = 0) {
        let label = '';

        if (depth > 1) {
            for (let i = 0; i < depth - 1; i++) {
                label += '-- ';
            }
        }

        if (variant.prefix) {
            label += '(' + variant.prefix + ') ';
        }

        label += variant.parentId === null ? Language.getTranslation('baseVariant') : variant.name;

        list.push({
            ...variant,
            name: variant.parentId === null ? Language.getTranslation('baseVariant') : variant.name,
            label: label,
            value: variant.id,
            isDisabled: variant.variantGroup || !variant.enabled,
        });

        if (variant.children.length > 0) {
            variant.children.forEach((childVariant) => {
                this.variantAsList(childVariant, list, depth + 1);
            });
        }

        return list;
    },
};

export default HelperFunctions;
