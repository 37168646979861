import { FormModal } from '../../../global/FormModal';
import { useEffect, useMemo, useState } from 'react';
import { CreateOrUpdateKioskCategory } from './CreateOrUpdateKioskCategory';
import { IconButton, InfoButton } from '../../../../components/Buttons';
import { Badge, Dropdown, SplitButton } from 'react-bootstrap';
import HelperFunctions from '../../../global/HelperFunctions';
import { useDeleteKioskCategoryMutation } from '../../../../features/security/authApi';
import { useActiveOrganisation } from '../../../../hooks/useActiveOrganisation';
import { useTranslation } from 'react-i18next';
import { ArrowClockwise, CaretRightFill, Check2 } from 'react-bootstrap-icons';
import { useGetPublicationsQuery, useRefreshKioskDataMutation } from '../../../../features/publications/publicationApi';
import Spinner from '../../../global/Spinner';
import { CategoryPublications } from './CategoryPublications';
import cx from 'classnames';

export function PublicationDomains({ domains }) {
    const [selectedKioskCategory, setSelectedKioskCategory] = useState(false);

    return (
        <div>
            {domains.map((domain) => (
                <Domain
                    domain={domain}
                    setSelectedKioskCategory={setSelectedKioskCategory}
                    key={`domain-${domain.id}`}
                />
            ))}

            {selectedKioskCategory && (
                <CreateKioskCategoryModal
                    selectedKioskCategory={selectedKioskCategory}
                    onHide={() => setSelectedKioskCategory(false)}
                />
            )}
        </div>
    );
}

function Domain({ domain, setSelectedKioskCategory }) {
    const { t } = useTranslation('publications');
    const [refreshKioskData] = useRefreshKioskDataMutation();
    const [isRefreshing, setRefreshing] = useState(false);

    const handleRefresh = () => {
        setRefreshing(true);

        refreshKioskData({ domainId: domain.id }).then(() => {
            setRefreshing(false);
        });
    };

    const sortedCategories = useMemo(() => {
        return [...domain.kioskCategories].sort(HelperFunctions.sortByString('categoryName'));
    }, [domain]);

    return (
        <div className="dr-container mb-4">
            <div
                className="data-table-group-header d-flex align-items-center py-2 px-3"
                style={{ backgroundColor: '#f3f5f7' }}
            >
                <span className="mr-2" style={{ fontWeight: 500 }}>
                    {domain.kioskName}
                </span>
                <span className="small">{domain.domain}</span>

                <div className="d-flex align-items-center ml-auto">
                    <RefreshingSpinner isSubmitting={isRefreshing} />

                    <IconButton
                        className="mx-2"
                        icon={<ArrowClockwise size={16} style={{ transform: 'rotate(45deg)' }} />}
                        onClick={handleRefresh}
                        tooltip="Kiosk data verversen"
                        disabled={isRefreshing}
                    />

                    <InfoButton
                        size="sm"
                        onClick={() =>
                            setSelectedKioskCategory({
                                publicationDomainId: domain.id,
                                categoryName: '',
                                organisationId: domain.organisationId,
                            })
                        }
                    >
                        {t('kiosk.btn_add_category')}
                    </InfoButton>
                </div>
            </div>

            <div className="bg-white p-3">
                {sortedCategories.map((category) => (
                    <Category
                        category={category}
                        domainId={domain.id}
                        setSelectedKioskCategory={setSelectedKioskCategory}
                        key={`category-${category.id}`}
                    />
                ))}
            </div>
        </div>
    );
}

export function RefreshingSpinner({ isSubmitting = false }) {
    const [showSaved, setShowSaved] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (isSubmitting && showSpinner === false) {
            // Starting save, show loading spinner
            setShowSpinner(true);
        } else if (isSubmitting === false && showSpinner) {
            // Save is done, show complete message
            setShowSpinner(false);
            setShowSaved(true);

            // Hide complete message after 2 seconds
            setTimeout(() => {
                setShowSaved(false);
            }, 2000);
        }
    }, [isSubmitting]);

    if (showSaved) {
        return <Check2 size={16} className="text-success" />;
    }

    if (isSubmitting && showSpinner) {
        return <Spinner className="" />;
    }

    return null;
}

function Category({ category, domainId, setSelectedKioskCategory }) {
    const { t } = useTranslation('publications');
    const [deleteKioskCategory] = useDeleteKioskCategoryMutation();
    const activeOrganisation = useActiveOrganisation();
    const [expanded, setExpanded] = useState(false);

    const { publications } = useGetPublicationsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            publications: data
                ? data
                      .filter(
                          (publication) =>
                              publication.domainId === domainId && publication.kioskCategoryId === category.id
                      )
                      .sort(HelperFunctions.sortByString('name'))
                : [],
        }),
    });

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'delete':
                HelperFunctions.confirmModal(
                    t('kiosk.deleteModal.confirmDeletion', { name: category.categoryName }),
                    'danger',
                    false,
                    t('kiosk.deleteModal.btn.confirm'),
                    t('kiosk.deleteModal.btn.cancel')
                )
                    .then(() => {
                        deleteKioskCategory({ kioskCategoryId: category.id, activeOrganisation });
                    })
                    .catch(() => {});
                break;
            default:
                break;
        }
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const canDelete = publications.length === 0;

    return (
        <div className="border mb-2 p-3">
            <div className="d-flex align-items-center">
                <CaretRightFill
                    className="flex-shrink-0"
                    size={12}
                    style={{ transform: expanded ? 'rotate(90deg)' : undefined }}
                />

                <div className="font-weight-bold px-1 mr-2 cursor-pointer" onClick={toggleExpanded}>
                    {category.categoryName}
                </div>

                {publications.length > 0 && <Badge variant="primary">{publications.length}</Badge>}

                <SplitButton
                    className="ml-auto"
                    id={`btn-edit-category-${category.id}`}
                    title={t('btn.edit')}
                    variant="primary"
                    size="sm"
                    menuAlign="right"
                    onClick={() => setSelectedKioskCategory(category)}
                    onSelect={handleSelect}
                >
                    <Dropdown.Item eventKey="delete" disabled={!canDelete}>
                        <span
                            className={cx({
                                'text-danger': canDelete,
                            })}
                        >
                            {t('btn.delete')}&hellip;
                        </span>
                    </Dropdown.Item>
                </SplitButton>
            </div>

            {expanded && <CategoryPublications publications={publications} categoryId={category.id} />}
        </div>
    );
}

function CreateKioskCategoryModal({ selectedKioskCategory, onHide }) {
    const { t } = useTranslation('publications');
    const mode = selectedKioskCategory.id === undefined ? 'create' : 'edit';

    return (
        <FormModal
            show={!!selectedKioskCategory}
            onHide={onHide}
            title={mode === 'create' ? t('kiosk.add_new_category') : t('kiosk.edit_category')}
        >
            {!!selectedKioskCategory && (
                <CreateOrUpdateKioskCategory
                    selectedKioskCategory={selectedKioskCategory}
                    mode={mode}
                    setShowModal={onHide}
                />
            )}
        </FormModal>
    );
}
