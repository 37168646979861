import { useGetCategoryGroup } from 'pages/reimbursements/hooks/useGetCategoryGroup';
import { Skeleton } from 'components/Skeleton';
import { GroupCategories, GroupHeader } from 'pages/reimbursements/Index';
import { useGetCategories } from 'pages/reimbursements/hooks/useGetCategories';
import { idToUri } from 'pages/global/UriHelper';
import { Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';

export function CategoryGroup({ folderId }) {
    const categories = useGetCategories(idToUri(folderId, 'CategoryGroup'));

    return (
        <Droppable droppableId={`reimbursement-group-${folderId}`} type="item">
            {(provided, snapshot) => (
                <div
                    className={cx('data-table-group data-table-group-droppable', {
                        'dragging-over': snapshot.isDraggingOver,
                    })}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <div className="dr-container">
                        <GroupTitle groupId={folderId} />

                        <div className="py-3">
                            <GroupHeader />
                            <GroupCategories categories={categories} />
                        </div>
                    </div>
                </div>
            )}
        </Droppable>
    );
}

function GroupTitle({ groupId }) {
    const group = useGetCategoryGroup(groupId);

    return (
        <div
            className="data-table-group-header d-flex align-items-center"
            style={{
                backgroundColor: '#f3f5f7',
                fontWeight: 500,
                padding: '0.55rem 0 0.55rem 1.2rem',
            }}
        >
            <div>{group ? <>{group.name}</> : <Skeleton />}</div>
        </div>
    );
}
