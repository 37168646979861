import { useGetCategoryGroupsQuery } from 'features/reimbursements/reimbursements';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetCategoryGroup(id) {
    const findGroup = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, id) => id,
            (data, id) => {
                if (!data) {
                    return null;
                }

                return data.find((categoryGroup) => categoryGroup.id === id);
            },
        );
    }, []);

    const { categoryGroup } = useGetCategoryGroupsQuery(undefined, {
        selectFromResult: (result) => ({
            categoryGroup: findGroup(result, id),
        }),
    });

    return categoryGroup;
}
