import { useGetCategoriesQuery } from 'features/reimbursements/reimbursements';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';

const emptyArray = [];

export function useGetCategories(categoryGroup = false) {
    const activeOrganisation = useActiveOrganisation();

    const filterCategories = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (res, organisationId, categoryGroup) => categoryGroup,
            (data, organisationId, categoryGroup) => {
                if (!data) {
                    return emptyArray;
                }

                const categories = data
                    .filter((category) => category.organisationId === organisationId)
                    .sort(HelperFunctions.dynamicSort('sortOrder'));

                if (categoryGroup === false) {
                    return categories;
                }

                return categories.filter((category) => category.categoryGroup === categoryGroup);
            },
        );
    }, []);

    const { categories } = useGetCategoriesQuery(undefined, {
        selectFromResult: (result) => ({
            categories: filterCategories(result, activeOrganisation, categoryGroup),
        }),
    });

    return categories;
}
