import Constants from '../../../../config/Constants';
import { FieldSelect, FormField, InputField, InputSelect, Switch } from 'pages/publications_v2/helpers/FieldHelper';
import { useSidebarContext } from '../../hooks/useSidebarContext';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export function BlockEditForm({ restrictions }) {
    const { entity } = useSidebarContext();

    return (
        <div>
            {entity.type === Constants.blockTypes.text && <TextBlockOptions restrictions={restrictions} />}

            {entity.type === Constants.blockTypes.blockLayout && <LayoutBlockOptions restrictions={restrictions} />}
            {entity.type === Constants.blockTypes.table && <TableOptions restrictions={restrictions} />}
            {entity.type === Constants.blockTypes.file && <FileBlockOptions restrictions={restrictions} />}
            {entity.type === Constants.blockTypes.label && <LabelBlockOptions restrictions={restrictions} />}

            {entity.type !== Constants.blockTypes.label && <PdfOptions restrictions={restrictions} />}
        </div>
    );
}

function TextBlockOptions({ restrictions }) {
    const { t } = useTranslation('documents');

    return (
        <div className="form-container">
            <div className="form">
                <div className="mb-3">
                    <label className="mb-2 font-weight-bolder label-title">
                        {t('document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.name')}
                    </label>
                    <InputField name="settings.properties.name" />
                </div>

                {restrictions?.canChangeBlockLayout && <TextLayoutOptions />}
                {restrictions?.canChangeBlockNumberingValue && <PrefixOptions />}
            </div>
        </div>
    );
}

function TextLayoutOptions() {
    const { t } = useTranslation('documents');

    return (
        <div className="mb-3">
            <label className="mb-2 font-weight-bolder label-title">
                {t('document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.blockLayout')}
            </label>
            <InputSelect
                name="settings.properties.textLayout"
                options={[
                    {
                        label: t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.text',
                        ),
                        value: 'default',
                    },
                    {
                        label: t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.introtext',
                        ),
                        value: 'intro',
                    },
                    {
                        label: t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.attention',
                        ),
                        value: 'attention',
                    },
                    {
                        label: t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.special',
                        ),
                        value: 'special',
                    },
                    {
                        label: t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.info',
                        ),
                        value: 'info',
                    },
                    {
                        label: t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.textBlockOptions.moreInfo',
                        ),
                        value: 'more-info',
                    },
                ]}
            />
        </div>
    );
}

function PrefixOptions() {
    const { values } = useFormikContext();
    const blockPrefixSettingValue = values.settings.properties.blockPrefixSetting ?? 'manual';
    const { t } = useTranslation('documents');

    return (
        <>
            <div className="form-container">
                <div className="form">
                    <div className="mb-3">
                        <label className="mb-2 font-weight-bolder label-title">
                            {t(
                                'document.navbar.main.editor.left.blocks.settingsSidebar.settings.prefixOptions.blockNumbering',
                            )}
                        </label>
                        <InputSelect
                            name="settings.properties.blockPrefixSetting"
                            defaultValue="manual"
                            options={[
                                {
                                    label: t(
                                        'document.navbar.main.editor.left.blocks.settingsSidebar.settings.prefixOptions.manual',
                                    ),
                                    value: 'manual',
                                },
                                {
                                    label: t(
                                        'document.navbar.main.editor.left.blocks.settingsSidebar.settings.prefixOptions.automatic',
                                    ),
                                    value: 'auto',
                                },
                            ]}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-2 font-weight-bolder label-title">
                            {t('document.navbar.main.editor.left.blocks.settingsSidebar.settings.prefixOptions.value')}
                        </label>

                        {blockPrefixSettingValue === 'manual' && <InputField name="settings.properties.blockPrefix" />}

                        {blockPrefixSettingValue === 'auto' && (
                            <InputSelect
                                name="settings.properties.blockPrefixType"
                                options={[
                                    {
                                        label: t(
                                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.prefixOptions.standard',
                                        ),
                                        value: 'default',
                                    },
                                    {
                                        label: t(
                                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.prefixOptions.lowercase',
                                        ),
                                        value: 'small_caps',
                                    },
                                    {
                                        label: t(
                                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.prefixOptions.uppercase',
                                        ),
                                        value: 'uppercase',
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

function LayoutBlockOptions() {
    const { t } = useTranslation('documents');

    return (
        <div className="form-container">
            <div className="form">
                <div className="mb-3">
                    <label className="mb-2 font-weight-bolder label-title">
                        {t('document.navbar.main.editor.left.blocks.settingsSidebar.settings.layoutBlockOptions.name')}
                    </label>
                    <InputField name="settings.properties.name" />
                </div>
                <div className="mb-3">
                    <label className="mb-2 font-weight-bolder label-title">
                        {t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.layoutBlockOptions.columnWidth',
                        )}
                    </label>
                    <InputField name="settings.properties.columnWidth" />
                </div>
                <div className="mb-3">
                    <label className="mb-2 font-weight-bolder label-title">Aangepaste stijl class naam</label>
                    <InputField name="settings.properties.styleClassName" />
                </div>
                <div className="mb-3">
                    <Switch
                        name="settings.properties.showTableHeader"
                        label={t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.layoutBlockOptions.showHeaders',
                        )}
                        formWidth="5"
                        labelWidth="6"
                    />
                    <Switch
                        name="settings.properties.highlightFirstColumn"
                        label={t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.layoutBlockOptions.showFirstColHeader',
                        )}
                        formWidth="5"
                        labelWidth="6"
                    />
                    <Switch
                        name="settings.properties.applySearchFilter"
                        label={t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.layoutBlockOptions.applySearchFilter',
                        )}
                        formWidth="5"
                        labelWidth="6"
                    />
                </div>
            </div>
        </div>
    );
}

function TableOptions() {
    const { t } = useTranslation('documents');

    return (
        <div className="form-container">
            <div className="form">
                <div className="mb-3">
                    <div>
                        <label className="mb-2 font-weight-bolder label-title">
                            {t(
                                'document.navbar.main.editor.left.blocks.settingsSidebar.settings.tableOptions.columnWidth',
                            )}
                        </label>
                        <InputField
                            name="settings.properties.columnWidth"
                            props={{ placeholder: 'Bijvoorbeeld: 20% 40% 40%' }}
                        />
                        <PrefixOptions />
                    </div>
                </div>
                <div className="mb-3">
                    <Switch
                        name="settings.properties.highlightFirstColumn"
                        label={t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.tableOptions.showFirstColHeader',
                        )}
                        formWidth="5"
                        labelWidth="6"
                    />
                </div>
            </div>
        </div>
    );
}

function PdfOptions() {
    const { t } = useTranslation('documents');

    return (
        <div className="form-container">
            <div className="form">
                <div className="mb-3">
                    <label className="mb-2 font-weight-bolder label-title">PDF</label>
                    <Switch
                        name="settings.properties.forceNewPage"
                        label={t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.pdfOptions.forceblock',
                        )}
                        formWidth="5"
                        labelWidth="6"
                    />
                </div>
            </div>
        </div>
    );
}

function FileBlockOptions() {
    const { t } = useTranslation('documents');
    const { values } = useFormikContext();

    return (
        <div className="form-container">
            <div className="form">
                <PrefixOptions />
                <div className="mb-3">
                    <FieldSelect
                        name="settings.properties.options.align"
                        label={t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.alignment',
                        )}
                        options={[
                            {
                                label: t(
                                    'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.left',
                                ),
                                value: 'left',
                            },
                            {
                                label: t(
                                    'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.middle',
                                ),
                                value: 'center',
                            },
                            {
                                label: t(
                                    'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.right',
                                ),
                                value: 'right',
                            },
                        ]}
                    />
                </div>

                <FormField
                    name="settings.properties.options.description"
                    label={t(
                        'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.caption',
                    )}
                />

                <FormField
                    name="settings.properties.options.alt"
                    label={t(
                        'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.altText',
                    )}
                />

                <FormField name="settings.properties.options.url" label="URL" props={{ placeholder: 'https://' }} />

                <Switch
                    name="settings.properties.options.customSize"
                    label={t(
                        'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.customDimensions',
                    )}
                />

                {values.settings.properties.options.customSize === true && (
                    <>
                        <FormField
                            name="settings.properties.options.width"
                            label={t(
                                'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.width',
                            )}
                            help={t(
                                'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.widthHelp',
                            )}
                            props={{
                                type: 'number',
                            }}
                        />

                        <FormField
                            name="settings.properties.options.height"
                            label={t(
                                'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.height',
                            )}
                            help={t(
                                'document.navbar.main.editor.left.blocks.settingsSidebar.settings.fileBlockOptions.heightHelp',
                            )}
                            props={{
                                type: 'number',
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

function LabelBlockOptions() {
    const { t } = useTranslation('documents');
    const { values } = useFormikContext();

    return (
        <div className="form-container">
            <div className="form">
                <div className="mb-3">
                    <label className="mb-2 font-weight-bolder label-title">
                        {t('document.navbar.main.editor.left.blocks.settingsSidebar.settings.labelBlockOption.name')}
                    </label>
                    <InputField name="settings.properties.name" />
                    <PrefixOptions />
                </div>

                <Switch
                    name="settings.properties.multiple"
                    label={t(
                        'document.navbar.main.editor.left.blocks.settingsSidebar.settings.labelBlockOption.multipleOptions',
                    )}
                />

                {values.settings.properties.multiple && (
                    <Switch
                        name="settings.properties.allow_add"
                        label={t(
                            'document.navbar.main.editor.left.blocks.settingsSidebar.settings.labelBlockOption.mayAdd',
                        )}
                    />
                )}
            </div>
        </div>
    );
}

function LinkedBlockOptions() {
    return <div></div>;
}
