import React, { useEffect, useState } from 'react';
import Constants, { Permissions } from '../../config/Constants';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import HelperFunctions from '../global/HelperFunctions';
import { Container, Dropdown, Row, SplitButton } from 'react-bootstrap';
import RestrictedContent from '../global/RestrictedContent';
import { useDispatch } from 'react-redux';
import RestrictedReimbursementContent, { useCurrentRole } from './RestrictedReimbursementContent';
import CloneApi from '../../api/CloneApi';
import { ArrowRepeat, Pencil } from 'react-bootstrap-icons';
import CrossRefMigrateModal from './views/CrossRefMigrateModal';
import { VIEW_PATH } from 'scenes/Reimbursements';
import {
    reimbursementApi,
    useEditCategoriesMutation,
    useMigrateCategoryFieldsMutation,
    useUpdateCategoryEditsMutation,
} from 'features/reimbursements/reimbursements';
import NewCategoryModal from './views/modals/NewCategoryModal';
import { ActionLinkButton, IconButton, InfoButton, WarningButton } from 'components/Buttons';
import MainContentNav from '../Navbar';
import { useGetCategories } from './hooks/useGetCategories';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Sidebar } from 'pages/reimbursements/views/index/Sidebar';
import { DataTable, DataTableGroup } from 'pages/data_table/DataTable';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { CategoryGroup } from 'pages/reimbursements/views/index/CategoryGroup';
import { useGetCategoryGroups } from 'pages/reimbursements/hooks/useGetCategoryGroups';
import { arrayMoveImmutable } from 'array-move';

export default function Index() {
    const dispatch = useDispatch();
    const categories = useGetCategories();
    const { t } = useTranslation('reimbursements');
    const [draggableType, setDraggableType] = useState(null);
    const [isDragging, setDragging] = useState(false);
    const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
    const [editCategories] = useEditCategoriesMutation();

    const hasDuplicatingCategories = categories.some((_category) => _category.cloneTaskId !== null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (hasDuplicatingCategories) {
                // Refresh every 5 seconds when there are duplicating categories
                dispatch({
                    type: `${reimbursementApi.reducerPath}/invalidateTags`,
                    payload: [{ type: 'Category', id: 'LIST' }],
                });
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [dispatch, hasDuplicatingCategories]);

    return (
        <>
            <MainContentNav title={t('breadcrumb')} />

            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <div
                    className={cx('content-static-body', {
                        'is-dragging-group': isDragging && draggableType === 'group',
                        'is-dragging-item': isDragging && draggableType === 'item',
                    })}
                >
                    <div className="d-flex align-items-stretch h-100">
                        <div className="content-sidebar overflow-auto">
                            <Sidebar handleMove={handleMove} />
                        </div>
                        <div className="flex-grow-1 overflow-auto" style={{ marginTop: 75 }}>
                            <div className="pb-3 px-4">
                                <div className="py-3 mx-4">
                                    <Categories handleMove={handleMove} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DragDropContext>

            <MainContentNavButtons setShowNewCategoryModal={setShowNewCategoryModal} />

            {showNewCategoryModal && (
                <NewCategoryModal showModal={showNewCategoryModal} handleClose={() => setShowNewCategoryModal(false)} />
            )}
        </>
    );

    function handleMove() {}

    function onDragEnd(result) {
        setDraggableType(null);
        setDragging(false);

        const { source, destination, type } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // Not moved
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }

        // // Group or item?
        // if (type === 'group') {
        //     handleGroup(result);
        // }

        if (type === 'item') {
            handleItem(result);
        }
    }

    function onDragStart({ type }) {
        setDraggableType(type);
        setDragging(true);
    }

    function handleItem(result) {
        const { source, destination, draggableId } = result;

        const payload = {
            draggableId,
            source: {
                index: source.index,
                id: source.droppableId === 'reimbursement-group-root' ? null : source.droppableId,
            },
            destination: {
                index: destination.index,
                id: destination.droppableId === 'reimbursement-group-root' ? null : destination.droppableId,
            },
        };

        if (payload.source.id === payload.destination.id) {
            // Sort within group
            moveCategories(source.index, destination.index, payload.source.id);
        } else {
            // Move to new group
            const sourceCategories = categories
                .filter((category) => category.categoryGroup === payload.source.id)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            const destinationCategories = categories
                .filter((category) => category.categoryGroup === payload.destination.id)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            // First remove from old group
            const [removed] = sourceCategories.splice(source.index, 1);

            // Insert into destination group
            destinationCategories.splice(destination.index, 0, removed);

            // Update source groups
            const sourceGroupChanges = sourceCategories.map((category, sortOrder) => ({
                id: category['@id'],
                changes: {
                    sortOrder,
                },
            }));

            // Update destination groups
            const destinationGroupChanges = destinationCategories.map((category, sortOrder) => {
                if (category.id === removed.id) {
                    return {
                        id: category['@id'],
                        changes: {
                            sortOrder,
                            categoryGroup: payload.destination.id,
                        },
                    };
                }

                return {
                    id: category['@id'],
                    changes: {
                        sortOrder,
                    },
                };
            });

            editCategories([...sourceGroupChanges, ...destinationGroupChanges]);
        }
    }

    function moveCategories(fromIndex, toIndex, categoryGroupId) {
        const categoriesLocal = categories
            .filter((category) => category.categoryGroup === categoryGroupId)
            .sort(HelperFunctions.dynamicSort('sortOrder'));

        const newCategories = arrayMoveImmutable(categoriesLocal, fromIndex, toIndex).map((category, sortOrder) => ({
            id: category['@id'],
            changes: {
                sortOrder,
            },
        }));

        editCategories(newCategories);
    }
}

function Categories({ handleMove }) {
    const { folderId } = useParams();

    return (
        <DataTable variant="reimbursements">
            {folderId ? (
                <CategoryGroup folderId={parseInt(folderId)} />
            ) : (
                <>
                    <RootCategories />
                    <RootLevelGroups handleMove={handleMove} />
                </>
            )}
        </DataTable>
    );
}

function RootCategories() {
    const categories = useGetCategories(null);

    if (categories.length === 0) {
        return null;
    }

    return (
        <div
            className={cx('mb-4', {
                'dr-container': categories.length > 0,
            })}
        >
            <Droppable isDropDisabled={true} droppableId="reimbursement-group-root" type="item">
                {(provided, snapshot) => (
                    <div
                        className={cx('data-table-group data-table-group-droppable', {
                            'dragging-over': snapshot.isDraggingOver,
                            'drop-disabled': true,
                        })}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <div className="py-3">
                            <GroupHeader />
                            <GroupCategories categories={categories} />

                            {provided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        </div>
    );
}

function RootLevelGroups({ handleMove }) {
    const groups = useGetCategoryGroups();

    return (
        <>
            {groups.map((_group, index) => (
                <DataTableGroup
                    name={_group.name}
                    level={0}
                    handleMove={(direction) => {
                        const isLast = groups.length - 1 === index;

                        if (direction === 'down' && isLast) {
                            return;
                        }

                        handleMove(direction, _group, index);
                    }}
                    key={`group-${_group.id}`}
                >
                    <ChildGroups parentId={_group['@id']} handleMove={handleMove} />
                </DataTableGroup>
            ))}
        </>
    );
}

function ChildGroups({ parentId, handleMove }) {
    const groups = useGetCategoryGroups(parentId);

    return (
        <>
            {groups.map((_group, index) => (
                <ChildGroup
                    group={_group}
                    handleMove={handleMove}
                    index={index}
                    isLast={groups.length - 1 === index}
                    key={`group-${_group.id}`}
                />
            ))}
        </>
    );
}

function ChildGroup({ group, handleMove, index, isLast }) {
    const categories = useGetCategories(group['@id']);

    return (
        <Droppable droppableId={group['@id']} type="item">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={cx('data-table-group-droppable dr-container mb-4', {
                        'dragging-over': snapshot.isDraggingOver,
                    })}
                >
                    <DataTableGroup
                        name={group.name}
                        level={1}
                        handleMove={(direction) => {
                            if (direction === 'down' && isLast) {
                                return;
                            }
                            handleMove(direction, group, index);
                        }}
                    >
                        <div className="mb-2 py-3">
                            <GroupHeader />
                            <GroupCategories categories={categories} folderId={group.id} />
                            {provided.placeholder}
                        </div>
                    </DataTableGroup>
                </div>
            )}
        </Droppable>
    );
}

export function GroupCategories({ categories = [], folderId }) {
    const { t } = useTranslation();
    const isDragDisabled = folderId === Constants.folders.trash;

    return (
        <div className="data-table-items">
            {categories.map((category, index) => (
                <Draggable
                    draggableId={category['@id']}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    key={`root-category-${category.id}`}
                >
                    {(provided, snapshot) => (
                        <div
                            id={`category-item-${category.id}`}
                            className={cx('data-table-item-wrapper', {
                                'is-dragging': snapshot.isDragging,
                            })}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(provided.draggableProps.style)}
                        >
                            <CategoryRow category={category} isDragDisabled={isDragDisabled} />
                        </div>
                    )}
                </Draggable>
            ))}

            {categories.length === 0 && folderId !== undefined && (
                <div className="p-3">
                    <div className="ml-4 mt-3 small text-secondary">{t('reimbursements:main.noReimbursements')}</div>
                </div>
            )}
        </div>
    );
}

function CategoryRow({ category, isDragDisabled = false }) {
    const [showCrossRefMigrateModal, setShowCrossRefMigrateModal] = useState(false);

    return (
        <>
            <div className="d-flex align-items-center p-2">
                <RxDragHandleDots2
                    size={22}
                    className={cx('icon-grip flex-shrink-0 mr-2', {
                        'text-muted': isDragDisabled,
                    })}
                />

                <div className="d-flex align-items-center flex-grow-1 border bg-white py-2 mr-2">
                    <Container fluid>
                        <Row className="align-items-center">
                            <div className="col-4">
                                <ReimbursementName category={category} />
                            </div>
                            <div className="col-2">
                                <ReimbursementStatus category={category} />
                            </div>
                            <div className="col-3">
                                <ReimbursementRole category={category} />
                            </div>
                            <div className="col d-flex justify-content-end">
                                <ReimbursementActions
                                    category={category}
                                    setShowCrossRefMigrateModal={setShowCrossRefMigrateModal}
                                />
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>

            {showCrossRefMigrateModal && (
                <CrossRefMigrateModal category={category} hideModal={() => setShowCrossRefMigrateModal(false)} />
            )}
        </>
    );
}

function ReimbursementName({ category }) {
    const history = useHistory();

    return (
        <>
            <ActionLinkButton
                onClick={() => {
                    history.push(
                        generatePath(VIEW_PATH, {
                            categoryId: category.id,
                        }),
                    );
                }}
                className="font-weight-bolder"
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {category.name}
            </ActionLinkButton>

            {category.description && (
                <div className="small text-muted" style={{ marginLeft: 3 }}>
                    {category.description}
                </div>
            )}
        </>
    );
}

function ReimbursementStatus({ category }) {
    const [updateCategoryEdits] = useUpdateCategoryEditsMutation();
    const [isUpdating, setIsUpdating] = useState(false);

    return (
        <>
            <Pencil className="text-secondary mr-2" size={14} />
            {category.unprocessedEdits}

            <IconButton
                tooltip="Refresh"
                icon={
                    <ArrowRepeat
                        className={cx({
                            'icon-spin': isUpdating,
                        })}
                        size={14}
                    />
                }
                className="text-secondary ml-2"
                style={{ marginBottom: 3 }}
                onClick={refreshCategory}
                disabled={isUpdating}
            />
        </>
    );

    function refreshCategory() {
        setIsUpdating(true);

        updateCategoryEdits(category['@id']).then(() => {
            setIsUpdating(false);
        });
    }
}

function ReimbursementRole({ category }) {
    const { t } = useTranslation('reimbursements');
    const currentRole = useCurrentRole(category.id);

    return <>{getRoleValue()}</>;

    function getRoleValue() {
        switch (currentRole) {
            case 'spectator':
                return t('settings.team.roles.spectator');
            case 'editor':
                return t('settings.team.roles.editor');
            case 'finalEditor':
                return t('settings.team.roles.finalEditor');
            case 'manager':
                return t('settings.team.roles.manager');
            case 'admin':
                return t('settings.team.roles.admin');
            default:
                return 'Unknown role';
        }
    }
}

function ReimbursementActions({ category, setShowCrossRefMigrateModal }) {
    const { t } = useTranslation('reimbursements');
    const dispatch = useDispatch();

    return (
        <>
            <RestrictedReimbursementContent
                categoryId={category.id}
                roles={[Constants.reimbursementTeamRoles.manager, Constants.reimbursementTeamRoles.admin]}
            >
                {category.fieldsMigrated ? (
                    <ReimbursementMenu
                        category={category}
                        handleCrossRefMigrate={handleCrossRefMigrate}
                        handleDuplicate={handleDuplicate}
                    />
                ) : (
                    <MigrateCategory category={category} />
                )}
            </RestrictedReimbursementContent>

            {category.fieldsMigrated && (
                <RestrictedReimbursementContent
                    categoryId={category.id}
                    roles={[
                        Constants.reimbursementTeamRoles.spectator,
                        Constants.reimbursementTeamRoles.editor,
                        Constants.reimbursementTeamRoles.finalEditor,
                    ]}
                >
                    <Link
                        to={generatePath(VIEW_PATH, {
                            categoryId: category.id,
                        })}
                        className="btn btn-primary btn-sm"
                    >
                        {t('main.category.openReimbursements')}
                    </Link>
                </RestrictedReimbursementContent>
            )}
        </>
    );

    function handleDuplicate(event) {
        event.preventDefault();

        HelperFunctions.confirmModal(
            `${category.name} ${t('main.category.duplicateNow')}`,
            undefined,
            false,
            t('main.category.yesDuplicate'),
        )
            .then(async () => {
                await CloneApi.createCloneTask('categories', category.id);

                // Reload Categories to get new status
                dispatch({
                    type: `${reimbursementApi.reducerPath}/invalidateTags`,
                    payload: [
                        {
                            type: 'Category',
                            id: 'LIST',
                        },
                    ],
                });
            })
            .catch(() => {});
    }

    function handleCrossRefMigrate(event) {
        event.preventDefault();

        setShowCrossRefMigrateModal(true);
    }
}

function ReimbursementMenu({ category, handleDuplicate, handleCrossRefMigrate }) {
    const { t } = useTranslation('reimbursements');
    const history = useHistory();

    return (
        <SplitButton
            id={`category-${category.id}-options-btn`}
            variant="primary"
            size="sm"
            title={t('main.dorpdown.openReimbursement')}
            onClick={handleClick}
            alignRight={true}
        >
            <Dropdown.Item onClick={handleDuplicate}>{t('main.dorpdown.duplicate')}&hellip;</Dropdown.Item>
            <Dropdown.Item onClick={handleCrossRefMigrate}>{t('main.dorpdown.migrateCrossRef')}&hellip;</Dropdown.Item>
        </SplitButton>
    );

    function handleClick() {
        history.push(
            generatePath(VIEW_PATH, {
                categoryId: category.id,
            }),
        );
    }
}

function MigrateCategory({ category }) {
    const [isMigrating, setMigrating] = useState(false);
    const [migrateCategoryFields] = useMigrateCategoryFieldsMutation();
    const { t } = useTranslation('reimbursements');

    return (
        <WarningButton
            onClick={() => {
                HelperFunctions.confirmModal(
                    t('main.messages.startMigration'),
                    undefined,
                    false,
                    t('main.btn.startMigration'),
                ).then(() => {
                    startMigration();
                });
            }}
            disabled={isMigrating}
        >
            {isMigrating ? `${t('main.messages.busyMigrating')}...` : `${t('main.messages.migratingContent')}...`}
        </WarningButton>
    );

    function startMigration() {
        setMigrating(true);

        migrateCategoryFields(category['@id']).then((result) => {
            if ('error' in result) {
                HelperFunctions.alertModal(t('main.error.migrationError'));
            }

            setMigrating(false);
        });
    }
}

function MainContentNavButtons({ setShowNewCategoryModal }) {
    const { t } = useTranslation('reimbursements');

    return (
        <RestrictedContent permission={Permissions.ReimbursementCategory.Create}>
            <div className="content-static-filters px-4 py-3">
                <div className="ml-auto d-flex align-items-center" style={{ marginRight: '25px', marginTop: '8px' }}>
                    <InfoButton className="ml-auto" onClick={() => setShowNewCategoryModal(true)}>
                        {t('main.btn.newReimbursement')}
                    </InfoButton>
                </div>
            </div>
        </RestrictedContent>
    );
}

export function GroupHeader() {
    const { t } = useTranslation('documents');

    return (
        <div className="d-flex align-items-center px-2 small font-weight-bolder" style={{ marginLeft: 22 }}>
            <div className="d-flex flex-grow-1 mx-2">
                <Container fluid>
                    <Row className="align-items-center">
                        <div className="col-4 text-muted">
                            <div className="pl-1">{t('documents.columnTitles.name')}</div>
                        </div>
                        <div className="col-2 text-muted">{t('documents.columnTitles.status')}</div>
                        <div className="col-3 text-muted">{t('documents.columnTitles.role')}</div>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
});
