import { generatePath, Redirect, Route } from 'react-router-dom';
import { ReactTitle } from 'components/ReactTitle';
import UpdateReimbursementCodes from '../pages/reimbursements/codes/Update';
import Index from '../pages/reimbursements/Index';
import UpdateProducts from '../pages/products/Update';
import UpdateProductTypes from '../pages/products/types/Update';
import Settings from '../pages/reimbursements/Settings';
import PropositionsIndex from '../pages/reimbursements/views/propositions/Index';
import EditProposition from '../pages/reimbursements/views/propositions/EditProposition';
import CollectivesIndex from '../pages/reimbursements/views/collectives/Index';
import Edit from '../pages/reimbursements/views/collectives/Edit';
import { CategoryMemo } from 'pages/reimbursements/views/category/Category';
import ReimbursementProduct from '../pages/reimbursements/views/reimbursement_product/ReimbursementProduct';
import { useTranslation } from 'react-i18next';

export const BASE_PATH = '/reimbursements';
export const INDEX_PATH = `${BASE_PATH}/:folder(folder)?/:folderId?`;
export const VIEW_PATH = `${BASE_PATH}/category/:categoryId/view/:reimbursementId?/:productId?`;
export const SETTINGS_PATH = `${BASE_PATH}/settings/:categoryId/:view`;
export const EDIT_REIMBURSEMENT_PRODUCT_PATH = `${BASE_PATH}/category/:categoryId/reimbursement_product/:reimbursementId/:parentProductId/:productId/:selectedProductId`;
export const EDIT_PRODUCTS_PATH = `${BASE_PATH}/products/update/categoryId/:categoryId`;
export const EDIT_PRODUCT_TYPES_PATH = `${BASE_PATH}/products/types/update/categoryId/:categoryId`;
export const EDIT_CODES_PATH = `${BASE_PATH}/codes/update/categoryId/:categoryId`;
export const PROPOSITIONS_PATH = `${BASE_PATH}/propositions/:categoryId/:folderId?`;
export const PROPOSITION_EDIT_PATH = `${BASE_PATH}/propositions/:categoryId/:folderId/proposition/:action/:propositionId?`;
export const COLLECTIVES_PATH = `${BASE_PATH}/collectives/:categoryId/:folderId?`;
export const COLLECTIVES_EDIT_PATH = `${BASE_PATH}/collectives/:categoryId/:folderId/collective/:action/:collectiveId?`;

export default function Reimbursements() {
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <ReactTitle title={t('breadcrumb')} />

            <Route path={`${INDEX_PATH}`} exact strict render={() => <SceneIndex />} />

            <Route
                path={VIEW_PATH}
                exact
                strict
                render={({ match }) => <CategoryMemo categoryId={parseInt(match.params.categoryId)} />}
            />
            <Route path={SETTINGS_PATH} exact strict render={() => <Settings />} />
            <Route
                path={EDIT_REIMBURSEMENT_PRODUCT_PATH}
                exact
                strict
                render={({ match }) => <ReimbursementProduct {...match.params} />}
            />
            <Route path={PROPOSITIONS_PATH} exact strict render={() => <PropositionsIndex />} />
            <Route path={PROPOSITION_EDIT_PATH} exact strict render={() => <EditProposition />} />
            <Route path={COLLECTIVES_PATH} exact strict render={() => <CollectivesIndex />} />
            <Route path={COLLECTIVES_EDIT_PATH} exact strict render={() => <Edit />} />

            <Route
                path={`${BASE_PATH}/update/categoryId/:id`}
                render={({ match }) => <Redirect to={generatePath(VIEW_PATH, { categoryId: match.params.id })} />}
            />
            <Route path={EDIT_CODES_PATH} render={() => <SceneUpdateReimbursementCodes />} />
            <Route path={EDIT_PRODUCTS_PATH} render={() => <SceneUpdateProducts />} />
            <Route path={EDIT_PRODUCT_TYPES_PATH} render={() => <SceneUpdateProductTypes />} />
        </>
    );
}

const SceneIndex = () => {
    return <Index />;
};

const SceneUpdateProducts = () => {
    return <UpdateProducts />;
};

const SceneUpdateProductTypes = () => {
    return <UpdateProductTypes />;
};

const SceneUpdateReimbursementCodes = () => {
    return <UpdateReimbursementCodes />;
};
