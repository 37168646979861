import { useGetDocument } from '../hooks/useGetDocument';
import { useGetDocumentTags } from '../../tags/hooks/useGetDocumentTags';
import { BlocksTagsModal } from '../../tags/BlocksTagModal';

export function EditTagModal({ tag, handleClose, filterByVariants = [] }) {
    const document = useGetDocument();

    // filterByVariants = array of ids
    const tags = useGetDocumentTags();

    const documentTag = tags.find((_tag) => _tag.name === tag);

    if (!documentTag) {
        return null;
    }

    return (
        <BlocksTagsModal
            documentId={document.id}
            tagId={documentTag.id}
            showModal={true}
            closeModal={handleClose}
            filterByVariants={filterByVariants}
        />
    );
}
