import Parser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { EditTagModal } from 'pages/documents_v2/modals/EditTagModal';
import { useMemo, useState } from 'react';

export function EntityTitle({ title, filterByVariants = [] }) {
    const { t } = useTranslation();
    const [showTagModal, setShowTagModal] = useState();

    const content = useMemo(() => {
        // A tag can start with "[Tag" or only "["
        return title.replace(/\[(Tag |)(.*?)\]/gm, (match) => {
            const shortTag = !match.startsWith('[Tag ');
            const tagName = match.slice(shortTag ? 1 : 5, match.length - 1);

            return `<button type="button" class="entity-title-tags-button" data-tag="${tagName}">${match}</button>`;
        });
    }, [title]);

    return (
        <>
            {Parser(content, {
                replace: ({ attribs }) => {
                    if (attribs && attribs.class === 'entity-title-tags-button') {
                        const tagValue = attribs['data-tag'];

                        return (
                            <span
                                data-uk-tooltip={t(
                                    'documents:document.navbar.main.editor.left.blocks.tooltips.editTag',
                                )}
                                className="entity-title-tags-button"
                                onClick={(e) => handleTagButtonClick(e, tagValue)}
                            >
                                {tagValue}
                            </span>
                        );
                    }
                },
            })}

            {!!showTagModal && (
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <EditTagModal
                        tag={showTagModal}
                        handleClose={() => setShowTagModal(undefined)}
                        filterByVariants={filterByVariants}
                    />
                </div>
            )}
        </>
    );

    function handleTagButtonClick(e, tagValue) {
        e.stopPropagation();

        setShowTagModal(tagValue);
    }
}
